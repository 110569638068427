import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { showSuccessToast, showErrorToast } from "../../Utils/toastUtils";
import { countries } from '../../data/dummy';
import { useNavigate } from 'react-router-dom';


const Form = (props) => {
  const { register, handleSubmit, setValue } = useForm();
  const [selectedDomain, setSelectedDomain] = useState('');
  const [comments, setComments] = useState("");
  const [formData, setFormData] = useState({});
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedSalesCountry, setSelectedSalesCountry] = useState('');
  const [otherCountry, setOtherCountry] = useState('');
  const [selectedPublisherDomain, setSelectedPublisherDomain] = useState('');
  const [isProcessing, setIsProcessing] = useState(false)

  const userType = sessionStorage.getItem('role');
  const navigate = useNavigate();
  useEffect(() => {
    axios.get(`/api/sales/one/${props.salesId}`, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('Token')
      }
    })
      .then((response) => response.data)
      .then((data) => {
        setSelectedSalesCountry(data.salesCountry)
        setSelectedCountry(data.country)
        setFormData(data);
        setComments(data.comments);
        populateFormFields(data);
        populateSalesFormFields(data);
      })
      .catch((error) => {
        console.log(error);
      }
      );



    axios.get(`/api/employee/emp`, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('Token')
      }
    })
      .then((response) => response.data)
      .then((data) => {
        setFormData(data);
        populateFormFields(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  const populateFormFields = (data) => {
    Object.keys(data).forEach((fieldName) => {
      const field = domains.flatMap((domain) => domain.fields).find((f) => f.name === fieldName);
      if (field) {
        setValue(fieldName, data[fieldName]);
      }
    });
  };

  const populateSalesFormFields = (data) => {
    Object.keys(data).forEach((fieldName) => {
      const field = salesDomains.flatMap((domain) => domain.fields).find((f) => f.name === fieldName);
      if (field) {
        setValue(fieldName, data[fieldName]);
      }
    });
  };

  useEffect(() => {
    setValue('comments', comments);
  }, [comments, setValue]);



  const handleCountryChange = (event) => {
    event.preventDefault();
    setSelectedCountry(event.target.value);
  };
  const handleCountrySalesChange = (event) => {
    event.preventDefault();
    setOtherCountry('')
    setSelectedSalesCountry(event.target.value);
  };

  const domains = [
    {
      name: 'General Info',
      fields: [
        { label: 'Company Name', type: 'text', name: 'companyName', Disabled: 'true' },
        { label: 'Street Address', type: 'text', name: 'streetAddress', Disabled: 'true' },
        { label: 'City/State/Zip', type: 'text', name: 'cityStateZip', Disabled: 'true' },
        { label: 'Country', type: 'text', name: 'country', Disabled: 'true' },
        { label: 'Pan Number', type: 'text', name: 'panNumber', Disabled: 'true', isDisabled: selectedCountry === 'India' ? false : true },
        { label: 'CIN Number', type: 'text', name: 'cinNumber', Disabled: 'true', isDisabled: selectedCountry === 'India' ? false : true },
        { label: 'Goods & Service Tax No.', type: 'text', name: 'gstNumber', Disabled: 'true', isDisabled: selectedCountry === 'India' ? false : true },
        { label: 'TAX / VAT NO.', type: 'text', name: 'taxID', isDisabled: selectedCountry === 'India' ? true : false },
        { label: 'URL', type: 'text', name: 'url', Disabled: 'true' },
      ],
    },
  ];

  const salesDomains = [
    {
      name: 'General Info',
      fields: [
        { label: 'Company Name', type: 'text', name: 'salesCompanyName' },
        { label: 'Street Address', type: 'text', name: 'salesStreetAddress' },
        { label: 'City/State/Zip', type: 'text', name: 'salesCityStateZip' },
        { label: 'Country', type: 'text', name: 'salesCountry' },
        { label: 'Pan Number', type: 'text', name: 'salesPanNumber', isDisabled: selectedSalesCountry === 'India' ? false : true },
        { label: 'CIN Number', type: 'text', name: 'salesCinNumber', isDisabled: selectedSalesCountry === 'India' ? false : true },
        { label: 'Goods & Service Tax No.', type: 'text', name: 'salesGstNumber', isDisabled: selectedSalesCountry === 'India' ? false : true },
        { label: 'TAX / VAT NO.', type: 'text', name: 'salesTaxID', isDisabled: selectedSalesCountry === 'India' ? true : false },
        { label: 'URL', type: 'text', name: 'salesUrl' },
      ],
    },
  ];

  const toggleDomain = (domainName) => {
    if (selectedDomain === domainName) {
      setSelectedDomain(null);
    } else {
      setSelectedDomain(domainName);
    }
  };

  const togglePublisherDomain = (domainName) => {
    if (selectedPublisherDomain === domainName) {
      setSelectedPublisherDomain(null);
    } else {
      setSelectedPublisherDomain(domainName);
    }
  };

  const onSubmit = (data) => {
    console.log(data)
    setIsProcessing(true);

    const domains = Object.keys(data);
    console.log(domains)
    const isEmpty = domains.some((domain) => {
      const fields = data[domain];
      console.log(fields)

      if (selectedCountry === "India" && domain === "taxID" && fields.length === 0) {
        if (data.taxID.length !== 0) {
          data.taxID = ' ';
        }
        return false;
      }

      if (selectedCountry !== "India" && (domain === "panNumber" || domain === "cinNumber" || domain === "gstNumber" || domain === "comments" || domain === "taxID") && fields.length === 0) {
        if (data.panNumber.length !== 0) {
          data.panNumber = ' ';
        }
        if (data.cinNumber.length !== 0) {
          data.cinNumber = ' ';
        }
        if (data.gstNumber.length !== 0) {
          data.gstNumber = ' ';
        }
        return false;
      }

      if (selectedSalesCountry === "India" && (domain === "salesTaxID" || domain === "comments" || domain === "taxID") && fields.length === 0) {
        if (data.salesTaxID.length !== 0) {
          data.salesTaxID = ' ';
        }
        return false;
      }
      if (selectedSalesCountry !== "India" && (domain === "salesPanNumber" || domain === "salesCinNumber" || domain === "salesGstNumber" || domain === "comments" || domain === "taxID") && fields.length === 0) {
        if (data.salesPanNumber.length !== 0) {
          data.salesPanNumber = ' ';
        }
        if (data.salesCinNumber.length !== 0) {
          data.salesCinNumber = ' ';
        }
        if (data.salesGstNumber.length !== 0) {
          data.salesGstNumber = ' ';
        }
        return false;
      }

      return fields.length === 0;
    });

    if (isEmpty) {
      showErrorToast("Error: Required fields not Filled!");
      setIsProcessing(false);
      return;
    }


    axios.put(`/api/sales/update/${props.salesId}`, data, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('Token')
      }
    })
      .then((response) => {
        showSuccessToast((response.data && response.data.Message) ? response.data.Message : "Success");;
        navigate('/advertisers');
        // props.getData();

      })
      .catch((err) => {
        console.log(err)
        setIsProcessing(false);
        showErrorToast("Registration Error!");
      })
      .finally(()=>{
        setIsProcessing(false)
      })

  }
  if (userType !== 'Publisher') {
    return (
      <>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" max-w-3xl mx-auto p-6 bg-gray-100 mt-12 rounded shadow-md animate-slide-in-2"
        >
          <h1 className='flex justify-center text-3xl mb-2 font-medium'>Advertiser </h1>
          {domains.map((domain, index) => (
            <div
              key={domain.name}
              className={`mb-4 p-6 border rounded transition-colors duration-300 ease-in-out ${selectedDomain === domain.name
                ? "border-blue-500"
                : "border-gray-300"
                }`}
            >
              <div
                className="flex justify-between items-center cursor-pointer transition-colors duration-300 ease-in-out"
                onClick={() => toggleDomain(domain.name)}
              >
                <h2 className="text-xl font-bold">{domain.name}</h2>
                {selectedDomain === domain.name ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-blue-500 transition-transform duration-300 ease-in-out transform rotate-180"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15.293 7.293a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414l-2.5-2.5-2.5 2.5a1 1 0 1 1-1.414-1.414l3-3a1 1 0 0 1 1.414 0zM5 11a1 1 0 0 0-1"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-500 transition-transform duration-300 ease-in-out transform rotate-0"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 3a1 1 0 0 1 1 1v5h5a1 1 0 0 1 0 2h-5v5a1 1 0 0 1-2 0v-5H4a1 1 0 1 1 0-2h5V4a1 1 0 0 1 1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </div>
              {selectedDomain === domain.name && (
                <div className="mt-4 transition-all duration-300 ease-in-out">
                  {domain.fields.map((field) => (
                    <div
                      style={{
                        display: field.isDisabled ? "none" : "block"
                      }
                      }
                      className="mb-4" key={field.name}>
                      {field.name === 'country' && (
                        <div>
                          <label htmlFor={field.name} className="block font-medium mb-2">
                            {field.label}:
                          </label>
                          <select
                            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                            id={field.name}
                            {...register(field.name)}
                            value={selectedCountry} onChange={handleCountryChange}
                            disabled={field.Disabled}
                            required>
                            {countries.map((country) => (
                              <option key={country.name} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      {field.name !== 'country' && (
                        <div>
                          <label htmlFor={field.name} className="block font-medium mb-2">
                            {field.label}:
                          </label>
                          <input
                            type={field.type}
                            disabled={field.Disabled}
                            id={field.name}
                            {...register(field.name)}
                            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                            required
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </form>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="max-w-3xl mx-auto p-6 bg-gray-100 mt-12 rounded shadow-md animate-slide-in-2"
        >
          <h1 className='flex justify-center text-3xl mb-2 font-medium'>Publisher </h1>
          {salesDomains.map((doMain, index) => (
            <div
              key={doMain.name}
              className={`mb-4 p-6 border rounded transition-colors duration-300 ease-in-out ${selectedPublisherDomain === doMain.name
                ? "border-blue-500"
                : "border-gray-300"
                }`}
            >
              <div
                className="flex justify-between items-center cursor-pointer transition-colors duration-300 ease-in-out"
                onClick={() => togglePublisherDomain(doMain.name)}
              >
                <h2 className="text-xl font-bold">{doMain.name}</h2>
                {selectedPublisherDomain === doMain.name ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-blue-500 transition-transform duration-300 ease-in-out transform rotate-180"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15.293 7.293a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414l-2.5-2.5-2.5 2.5a1 1 0 1 1-1.414-1.414l3-3a1 1 0 0 1 1.414 0zM5 11a1 1 0 0 0-1"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-500 transition-transform duration-300 ease-in-out transform rotate-0"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 3a1 1 0 0 1 1 1v5h5a1 1 0 0 1 0 2h-5v5a1 1 0 0 1-2 0v-5H4a1 1 0 1 1 0-2h5V4a1 1 0 0 1 1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </div>
              {selectedPublisherDomain === doMain.name && (
                <div className="mt-4 transition-all duration-300 ease-in-out">
                  {doMain.fields.map((field) => (
                    <div
                      style={{
                        display: field.isDisabled ? "none" : "block"
                      }
                      }
                      className="mb-4" key={field.name}>
                      {field.name === 'salesCountry' && (
                        <div>
                          <label htmlFor={field.name} className="block font-medium mb-2">
                            {field.label}:
                          </label>
                          <select
                            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                            id={field.name}
                            {...register(field.name)}
                            value={selectedSalesCountry}
                            onChange={handleCountrySalesChange}
                            disabled={field.Disabled}
                            required>
                            {countries.map((country) => (
                              <option key={country.name} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      {(field.name !== 'salesCountry' && (field.name === 'salesPanNumber' || field.name === 'salesCinNumber' || field.name === 'salesGstNumber')) && (
                        <div>
                          <label htmlFor={field.name} className="block font-medium mb-2">
                            {field.label}:
                          </label>
                          <input
                            type={field.type}
                            disabled={field.Disabled}
                            id={field.name}
                            {...register(field.name)}
                            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                            required={selectedSalesCountry === 'India'}
                          />
                        </div>
                      )}
                      {(field.name !== 'salesCountry' && field.name === 'salesTaxID') && (
                        <div>
                          <label htmlFor={field.name} className="block font-medium mb-2">
                            {field.label}:
                          </label>
                          <input
                            type={field.type}
                            disabled={field.Disabled}
                            id={field.name}
                            {...register(field.name)}
                            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                            required={selectedSalesCountry !== 'India'}
                          />
                        </div>
                      )}
                      {(field.name !== 'salesCountry' && field.name !== 'salesPanNumber' && field.name !== 'salesCinNumber' && field.name !== 'salesGstNumber' && field.name !== 'salesTaxID') && (
                        <div>
                          <label htmlFor={field.name} className="block font-medium mb-2">
                            {field.label}:
                          </label>
                          <input
                            type={field.type}
                            disabled={field.Disabled}
                            id={field.name}
                            {...register(field.name)}
                            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                            required
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}

          <div className="mb-4 p-6 border rounded transition-colors duration-300 ease-in-out border-blue-500">
            <label>
              Any Comments
            </label>
            <div className="mt-2">
              <textarea
                id="comments"
                {...register("comments")}
                className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                placeholder="Enter comments"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              ></textarea>
            </div>
          </div>

          <button
            disabled={isProcessing}
            type="submit"
            className={`${isProcessing ? "bg-gray-500 hover:bg-gray-700" : "bg-blue-500 hover:bg-blue-700"} flex mx-auto  text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out`}
            >
            Edit
          </button>

        </form>
      </>
    );
  }
};

export default Form;