

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showSuccessToast, showErrorToast } from "../Utils/toastUtils";
import { useStateContext } from "../contexts/ContextProvider";



const GeneratePDF1 = (props) => {
    const { currentColor } = useStateContext();
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [file, setFile] = useState(null);



    const [isLoading, setIsLoading] = useState(false);
    const modal = props.modal;
    const setModal = props.setModal;
    const contractId = props.contractId;

    const toggleModal = () => {
        setModal(!modal);
        props.getData();
    };

    useEffect(() => {
        getSigns()
    }, [props.contractId])

    const handleSubmit = async (e) => {
        e.preventDefault()

        let formData = new FormData();
        formData.append("name", name)
        formData.append("signatureLink", file)

        setIsLoading(true)
        if (file === null || file === '') {
            showErrorToast("Select Signature File");
            setIsLoading(false)
            return;
        }
        try {
            const response = await axios.post(`/api/pdf/generatePdf/${contractId}`,
                formData,
                {
                    headers: {
                        Authorization: 'Bearer ' + sessionStorage.getItem('Token'),
                        'Content-Type': 'multipart/form-data'
                    }
                });

            showSuccessToast(response.data.Message);
            props.getData()
        } catch (error) {
            setIsLoading(false)
            showErrorToast("PDF Generation Error!");
            props.getData()
            console.error('Error occurred:', error);
        } finally {
            setSelectedSign("");
            setModal(false)
            setIsLoading(false)
            setModal(false)
            setFile("")
            setUrl("")
            setName("")

        }
    };

    const getSigns = () => {
        setLoading(true);
        axios.get('/api/pdf/getsignatures', {
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('Token')
            }
        })
            .then(response => {
                setSigns(response.data);
                // setSelectedSign(response && response.data ? response.data[0] : "")
            })
            .catch(error => {
                console.error(error);
            }).finally(() => {
                setLoading(false);
            })
    };

    const [signs, setSigns] = useState([])
    const [selectedSign, setSelectedSign] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleSignChange = (event) => {
        setFile(event.target.files[0])
        setUrl(event.target.value)
    };


    return (
        <div className="flex items-center justify-center">
            {modal && (
                <div className="fixed z-50 inset-0 flex items-center justify-center">
                    <div className="w-full max-w-xl mx-auto my-6 bg-white rounded-lg shadow-lg">
                        <div className="px-8 py-6">
                            <div className="flex justify-between items-center mb-6">
                                <div className="text-xl font-bold">Generate Agreement</div>
                                <button
                                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                                    onClick={toggleModal}
                                >
                                    <svg
                                        className="h-6 w-6 fill-current"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 8.586l4.95-4.95a1 1 0 0 1 1.415 1.415L11.414 10l4.95 4.95a1 1 0 0 1-1.415 1.415L10 11.414l-4.95 4.95a1 1 0 1 1-1.415-1.415L8.586 10 3.636 5.05A1 1 0 0 1 5.05 3.636L10 8.586z"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="mb-6">
                                    <label
                                        htmlFor="name"
                                        className="block text-gray-700 font-bold mb-2"
                                    >
                                        Admin Name
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-6">
                                    <label
                                        htmlFor="phoneNumber"
                                        className="block text-gray-700 font-bold mb-2"
                                    >
                                        Signature URL
                                    </label>
                                    <input
                                        type="file"
                                        id="file-sign"
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                                        // value={url}
                                        onChange={(event) => handleSignChange(event)}
                                        required
                                    />
                                </div>

                                {/* <div>
                                    <select
                                        className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                                        value={selectedSign} onChange={handleSignChange} required>
                                        <option  >Select an option</option >

                                        {signs.map((sign) => (
                                            <option key={sign._id} value={sign._id} >
                                                {sign.name}
                                            </option>
                                        ))}
                                    </select>
                                </div> */}
                                <div className="text-center">
                                    <button
                                        style={{ backgroundColor: currentColor }}
                                        type="submit"
                                        className={`mt-4 px-6 py-3 ${isLoading ? "bg-gray-500" : "bg-blue-500"}  text-white font-bold rounded-lg ${isLoading ? "hover:bg-gray-700" : "hover:bg-blue-700"}  `}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? "Generating..." : "Generate"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            {modal && (
                <div
                    className="fixed inset-0 bg-gray-800 opacity-50"
                    onClick={toggleModal}
                ></div>
            )}
        </div>
    );
};

export default GeneratePDF1;
