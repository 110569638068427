const handleResponse = (error, navigate) => {

  console.log(error)
  if (error.response.status === 200) {
    // Success response, continue with the current flow
    return;
  } else if (error.response.status === 401) {
    // Unauthorized error, redirect to the 401 page
    navigate('/err/unauthorized');
  } else if (error.response.status === 404) {
    // Page not found error, redirect to the 404 page
    navigate('/err/pagenotfound');
  } else if (error.response.status === 500) {
    // Internal server error, redirect to the 500 page

    navigate('/err/internalserver');
  } else {
    return;
  }
};

export default handleResponse;
