

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showSuccessToast, showErrorToast } from "../Utils/toastUtils";
import { useStateContext } from "../contexts/ContextProvider";

const ChangeEmployeeContract = (props) => {
    const { currentColor } = useStateContext();
    const [employees, setEmployees] = useState(false)
    const [loading, setLoading] = useState(false)


    const [isLoading, setIsLoading] = useState(false);
    const modal = props.modal;
    const setModal = props.setModal;
    const pubId = props.pubId;
    const fetchData = props.fetchData;

    const userType = sessionStorage.getItem('role');


    const toggleModal = () => {
        setModal(!modal);
        fetchData()
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        const data = {
            publisherId: pubId._id,
            employeeId: selectedEmp

        }
        console.log(data)
        try {
            const response = await axios.put(`/api/publisher/transferpublisher/`,
                data,
                {
                    headers: {
                        Authorization: 'Bearer ' + sessionStorage.getItem('Token'),
                    }
                });


            showSuccessToast(response.data.Message ? response.data.Message : "Successfully Transferred");
            fetchData()
        } catch (error) {
            setIsLoading(false)
            showErrorToast("Error Transfering Publisher!");
            fetchData()
            console.error('Error occurred:', error);
        } finally {
            setModal(false)
            setIsLoading(false)
            setModal(false)

        }
    };

    useEffect(() => {
        if (userType === 'Admin') {
            getEmployees()
        }
    }, [props.pubId])


    const getEmployees = () => {
        setLoading(true);
        axios.get('/api/employee/transfer/all', {
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('Token')
            }
        })
            .then(response => {
                setEmployees(response.data);
                setSelectedEmp(response.data[0])
                console.log(response.data)
            })
            .catch(error => {
                console.error(error);
                setLoading(false);

            }).finally(() => {
                setLoading(false);
            })
    };
    const handleEmpChange = (event) => {
        event.preventDefault();
        setSelectedEmp(event.target.value);
    };
    const [selectedEmp, setSelectedEmp] = useState('')
    return (
        <div className="flex items-center justify-center">
            {modal && (
                <div className="fixed z-50 inset-0 flex items-center justify-center">
                    <div className="w-full max-w-xl mx-auto my-6 bg-white rounded-lg shadow-lg">
                        <div className="px-8 py-6">
                            <div className="flex justify-between items-center mb-6">
                                <div className="text-xl font-bold">Change Employee</div>
                                <button
                                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                                    onClick={toggleModal}
                                >
                                    <svg
                                        className="h-6 w-6 fill-current"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 8.586l4.95-4.95a1 1 0 0 1 1.415 1.415L11.414 10l4.95 4.95a1 1 0 0 1-1.415 1.415L10 11.414l-4.95 4.95a1 1 0 1 1-1.415-1.415L8.586 10 3.636 5.05A1 1 0 0 1 5.05 3.636L10 8.586z"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="mb-6">
                                    <label
                                        htmlFor="name"
                                        className="block text-gray-700 font-bold mb-2"
                                    >
                                        Publisher Name
                                    </label>
                                    <div
                                        type="text"
                                        id="name"
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                                    >
                                        {pubId ? pubId.name : "Error Fetching Name"}
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <label
                                        htmlFor="name"
                                        className="block text-gray-700 font-bold mb-2"
                                    >
                                        Current Employee
                                    </label>
                                    <div
                                        type="text"
                                        id="name-old"
                                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                                    >
                                        {pubId && pubId.employeeId ? `${pubId.employeeId.firstName} ${pubId.employeeId.lastName} ` : "Error Fetching Name"}
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <label
                                        htmlFor="name"
                                        className="block text-gray-700 font-bold mb-2"
                                    >
                                        New Employee
                                    </label>
                                    <div>
                                        <select
                                            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                                            value={selectedEmp}
                                            onChange={handleEmpChange}
                                            required
                                        >
                                            {employees && employees.map((emp) => (
                                                <option key={emp._id} value={emp._id}>
                                                    {emp.firstName}  {emp.lastName}   ( {emp.emailId} )


                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <button
                                        style={{ backgroundColor: currentColor }}
                                        type="submit"
                                        className={`mt-4 px-6 py-3 ${isLoading ? "bg-gray-500" : "bg-blue-500"}  text-white font-bold rounded-lg ${isLoading ? "hover:bg-gray-700" : "hover:bg-blue-700"}  `}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? "Updating..." : "Update"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            {modal && (
                <div
                    className="fixed inset-0 bg-gray-800 opacity-50"
                    onClick={toggleModal}
                ></div>
            )}
        </div>
    );
};

export default ChangeEmployeeContract;
