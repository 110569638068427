import React from 'react';
import { Link } from 'react-router-dom';
import unauthorized from '../src/assets/unauthorized.svg';
const Unauthorized = () => {
  return (
    <section className="bg-white font-serif">
      <div className='flex justify-center flex-col'>
        <div className='flex justify-center  '>
          <img className="h-auto min-w-0 max-w-4xl" src={unauthorized} alt="error-401" />
        </div>
        <div className='text-center'>

          <Link to="/dashboard" className="link_404 inline-block mt-20 px-10 py-2 bg-green-500 text-white">
            Go to Home
          </Link>
        </div>
      </div>



    </section>
  );
};

export default Unauthorized;
