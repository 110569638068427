const dateFormat = (dateString) => {
    const datetimeString = dateString;
    const _date = new Date(datetimeString);

    const day = String(_date.getUTCDate()).padStart(2, "0");
    const month = String(_date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
    const year = _date.getUTCFullYear();

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
};

export default dateFormat;
