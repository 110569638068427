import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import TopCard from "../../cards/TopCard";
import ActivityLogs from "../../components/ActivityLogs";
import PocPublisher from "../../components/PocPublisher";
import OverallStats from "../../components/OverallStats";
import axios from 'axios'
import { showErrorToast } from "../../Utils/toastUtils";
import { RiAdminFill } from 'react-icons/ri';
import { PiMoneyBold } from 'react-icons/pi';
import { BiSolidUserCircle } from 'react-icons/bi';
import { ImCross } from 'react-icons/im';
import { FaUserGroup } from 'react-icons/fa6';


const DashBoard = (props) => {

    const { startTour } = props;
    const UserType = sessionStorage.getItem('role');
    const navigate = useNavigate()

    const [cards, setCards] = useState([])
    const [loading, setLoading] = useState([]);
    const [iconsSet, setIconsSet] = useState([])
    const [navigateLinks, setNavigateLinks] = useState([])


    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await axios.get('/api/dashboard/dash', {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('Token')
                }
            })
            const DATA_SORT = result.data.user;
            if (DATA_SORT?.length === 5) {
                setIconsSet([<RiAdminFill />, <BiSolidUserCircle />, <FaUserGroup />, <PiMoneyBold />, <ImCross />])
                setNavigateLinks(['employees', 'employees', 'publishers', 'employees', 'employees'])
            } else if (UserType === 'Publisher') {
                setIconsSet([<RiAdminFill />])
                setNavigateLinks(['form'])
            }

            else {
                setIconsSet([<FaUserGroup />])
                setNavigateLinks(['publishers'])
            }
            setCards(DATA_SORT)
        } catch (error) {

            showErrorToast("Error!");
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (sessionStorage.getItem('Token') === null) {
            navigate('/')
        }
        fetchData();

        setTimeout(() => {
            if (sessionStorage.getItem("newUser") === "true") {
                startTour()
                sessionStorage.removeItem("newUser")
            }

        }, 2000)
    }, [])
    return (

        <div className="mt-20">

            <div>

                <div className=" step-one animate-slide-in-2  flex px-5 flex-wrap grow lg:flex-nowrap flex-center justify-start ml-1 item-center w-100 ">
                    {
                        cards?.length > 0 && cards.map((item, index) => {
                            const ICON = iconsSet[index];
                            const LINK = navigateLinks[index]

                            return (
                                <TopCard
                                    key={index}
                                    title={item.role_name}
                                    figure={item.count}
                                    icon={ICON}
                                    link={LINK}
                                />
                            )
                        })
                    }
                </div>
                <div className="flex flex-wrap flex-row lg:flex-nowrap justify-around w-full  ">
                    {
                        UserType === 'Admin' && (<OverallStats />)

                    }
                    {

                        UserType === 'Publisher' && (<PocPublisher />)
                    }
                    {

                        < ActivityLogs />
                    }
                </div>
            </div>

        </div>
    );
};

export default DashBoard;
