import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { ToastContainer } from "react-toastify";
import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";

import {
  Publishers,
  Companies,
  PublisherDetails,
  PublisherDocuments,
  AdminDashBoard,
  UserLogin,
  ForgotPassword,
  ResetPassword,
  Profile,
  Logs,
  PdfApproval
} from "./pages";
import "./App.css";
import UserDetails from "./pages/UserDetails";
import { useStateContext } from "./contexts/ContextProvider";
import EachUser from "./pages/eachUser";
import Contract from "./pages/Contract";
import CompanyDetails from "./pages/CompanyDetails";
import Protected from "./Protected";
import PublisherFormDetails from "./pages/PublisherFormDetails";
import EmployeeDocuments from "./pages/employeeDocuments";
import PublisherForm from "./pages/Forms/publisherForm";
import Sales from "./pages/Sales";
import SalesDocuments from "./pages/salesDocuments";
import NotFoundRedirect from "./notfoundredirect"
import PageNotFound from './404Page';
import InternalServerError from './500Page';
import Unauthorized from './401Page';
import EditContract from "./pages/SalesEdit";
import Joyride from 'react-joyride';

const App = () => {
  const location = useLocation()
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  const isLoginPage = location.pathname === '/' || location.pathname === '/login' || location.pathname === '/forgot-password' || location.pathname.startsWith('/reset-password') || location.pathname.startsWith('/err')
  const [isSignedIn, setIsSignedIn] = React.useState(sessionStorage.getItem('isSignedIn') === 'true')

  const signin = () => {
    setIsSignedIn(true)
    sessionStorage.setItem('isSignedIn', 'true')
  }

  const signout = () => {
    setIsSignedIn(false)
    sessionStorage.setItem('isSignedIn', 'false')
  }

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
    if (sessionStorage.getItem('isSignedIn') === null) {
      sessionStorage.setItem('isSignedIn', 'false')
    }
  });


  const [run, setRun] = React.useState(false);
  const steps = [
    {
      target: '.step-one',
      content: 'This is a top level view of your work!',
      disableBeacon: true,
    },
    {
      target: '.step-two',
      content: 'A table with progresses!',
    },
    {
      target: '.step-three',
      content: 'Shows your recent activity logs!',
    },
    {
      target: '.step-four',
      content: 'Logout from here and access your profile here!',
    },
  ]


  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if (status === 'finished') {
      setRun(false);
    }
  };

  const startTour = () => {
    setRun(true);
  };

  return (

    <div>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <Joyride
        steps={steps}
        continuous
        run={run}
        callback={handleJoyrideCallback}
        showProgress
        showSkipButton
        styles={{
          options: {
            arrowColor: '#e3ffeb',
            primaryColor: '#000',
            textColor: '#004a14',
            width: 400,
            zIndex: 1000,
          }
        }}
      />
      <div className={currentMode === "Dark" ? "dark" : ""}>
        <div className="flex relative dark:bg-main-dark-bg">
          {!isLoginPage && <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
            <TooltipComponent content="Settings" position="Top">
              <button
                type="button"
                onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: "50%" }}
                className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>}
          {activeMenu && !isLoginPage && (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
              <Sidebar />
            </div>
          )}
          <div
            className={
              activeMenu && !isLoginPage
                ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
                : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
            }
          >
            {!isLoginPage && <div className=" fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
              <Navbar />
            </div>}
            <div>
              {themeSettings && <ThemeSettings />}

              <Routes>
                <Route path="*" element={<NotFoundRedirect />} />
                <Route path="/err/pagenotfound" element={<PageNotFound />} />
                <Route path="/err/internalserver" element={<InternalServerError />} />
                <Route path="/err/unauthorized" element={<Unauthorized />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />

                <Route path="/" element={
                  <UserLogin signin={signin} />
                } />
                <Route path="/dashboard" element={
                  <Protected isSignedIn={isSignedIn}>
                    <AdminDashBoard signin={signin} signout={signout} startTour={startTour} />
                  </Protected>
                } />

                <Route exact path="/publisher/:id" element={
                  <Protected isSignedIn={isSignedIn}>
                    <PublisherDetails signin={signin} signout={signout} />
                  </Protected>
                } />
                <Route exact path="/publisher/docs/:id" element={
                  <Protected isSignedIn={isSignedIn}>
                    <PublisherDocuments signin={signin} signout={signout} />
                  </Protected>
                } />
                <Route exact path="/document" element={
                  <Protected isSignedIn={isSignedIn}>
                    <PublisherDocuments signin={signin} signout={signout} />
                  </Protected>
                } />
                <Route exact path="/publisher/contract/:id/edit" element={
                  <Protected isSignedIn={isSignedIn}>
                    <Contract signin={signin} signout={signout} />
                  </Protected>
                } />
                <Route exact path="/publisher/contract/:id" element={
                  <Protected isSignedIn={isSignedIn}>
                    <Contract signin={signin} signout={signout} />
                  </Protected>
                } />

                <Route exact path="/company/:id" element={
                  <Protected isSignedIn={isSignedIn}>
                    <CompanyDetails signin={signin} signout={signout} />
                  </Protected>
                } />
                <Route path="/publishers" element={
                  <Protected isSignedIn={isSignedIn}>
                    <Publishers signin={signin} signout={signout} />
                  </Protected>
                } />
                <Route path="/companies" element={
                  <Protected isSignedIn={isSignedIn}>
                    <Companies signin={signin} signout={signout} />
                  </Protected>
                } />
                <Route path="/employees" element={
                  <Protected isSignedIn={isSignedIn}>
                    <UserDetails signin={signin} signout={signout} />
                  </Protected>
                } />
                <Route exact path="/employees/:id" element={
                  <Protected isSignedIn={isSignedIn}>
                    <EachUser signin={signin} signout={signout} />
                  </Protected>
                } />
                <Route exact path="/employee/docs/:id" element={
                  <Protected isSignedIn={isSignedIn}>
                    <EmployeeDocuments signin={signin} signout={signout} />
                  </Protected>
                } />
                <Route path="/form" element={
                  <Protected isSignedIn={isSignedIn}>
                    <PublisherFormDetails signin={signin} signout={signout} />
                  </Protected>
                } />
                <Route path="/advertisers" element={
                  <Protected isSignedIn={isSignedIn}>
                    <Sales signin={signin} signout={signout} />
                  </Protected>
                } />
                <Route exact path="/advertisers/docs/:id" element={
                  <Protected isSignedIn={isSignedIn}>
                    <SalesDocuments signin={signin} signout={signout} />
                  </Protected>
                } />
                <Route exact path="/advertisers/:id/edit" element={
                  <Protected isSignedIn={isSignedIn}>
                    <EditContract signin={signin} signout={signout} />
                  </Protected>
                } />
                <Route exact path="/advertisers/:id" element={
                  <Protected isSignedIn={isSignedIn}>
                    <EditContract signin={signin} signout={signout} />
                  </Protected>
                } />
                <Route path="/form/:id" element={
                  <Protected isSignedIn={isSignedIn}>
                    <PublisherForm signin={signin} signout={signout} />
                  </Protected>
                } />
                <Route path="/profile" element={
                  <Protected isSignedIn={isSignedIn}>
                    <Profile signin={signin} signout={signout} />
                  </Protected>
                } />

                <Route path="/logs" element={
                  <Protected isSignedIn={isSignedIn}>
                    <Logs signin={signin} signout={signout} />
                  </Protected>
                } />

                <Route path="/agreements" element={
                  <Protected isSignedIn={isSignedIn}>
                    <PdfApproval signin={signin} signout={signout} />
                  </Protected>
                } />
              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
