import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from ".";
import { useStateContext } from "../contexts/ContextProvider";
import avatar from "../data/avatar.jpg";
import { FaRegUserCircle } from "react-icons/fa";
import { BiSolidUser } from "react-icons/bi";
import { ImCancelCircle } from "react-icons/im";

const UserProfile = () => {
  const navigate = useNavigate()
  const {
    color,
    bgHoverColor,
    size,
    borderRadius,
    width,
    currentColor,
    setIsClicked,
  } = useStateContext();

  const { firstname, lastname, role, email } = sessionStorage.getItem("UserDetails") ? JSON.parse(sessionStorage.getItem("UserDetails")) : {}

  const logout = (e) => {
    e.preventDefault()
    setIsClicked(false);
    sessionStorage.clear()
    window.location.replace(
      "/"
    );
    navigate('/', { replace: true });
  }

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<ImCancelCircle />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">

        <FaRegUserCircle
          className="rounded-full h-24 w-24"
          src={avatar}
          alt="user-profile" />

        <div>
          <p className="font-semibold text-xl dark:text-gray-200">
            {" "}
            {firstname && lastname ? firstname + " " + lastname : "No Name Found"}
          </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">
            {" "}
            {role ? role : "No Role Found"}
          </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {" "}
            {email ? email : "No Mail ID Found"}
          </p>
        </div>
      </div>
      <div>
        <div
          className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#000]"
          onClick={() => {
            navigate('/profile');
            setIsClicked(false);
          }}

        >
          <button
            type="button"
            // style={{ color: item.iconColor, backgroundColor: item.iconBg }}
            className=" text-xl rounded-lg p-3 hover:bg-light-gray"
          >
            <BiSolidUser />
          </button>

          <div>
            <p className="font-semibold dark:text-gray-200 ">Profile</p>
            <p className="text-gray-500 text-sm dark:text-gray-400">
              {" "}
              Account Setting
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <button
          type="button"
          onClick={(e) => logout(e)}
          style={{ backgroundColor: currentColor, color, borderRadius }}
          className={` w-full rounded-xl text-${size} p-3 w-${width} hover:drop-shadow-xl text-white text-xl hover:bg-${bgHoverColor}`}
        >
          Log Out
        </button>
      </div>
    </div>
  );
};

export default UserProfile;
