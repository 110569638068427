import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useLocation, Navigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { showSuccessToast, showErrorToast } from "../../Utils/toastUtils";
import { countries } from '../../data/dummy';
import dateFormat from '../../Utils/dateFormatter';
import { useNavigate } from 'react-router-dom';
import handleResponse from '../../Utils/responseHandler';

const Form = () => {
  const { register, handleSubmit, setValue } = useForm();
  const [selectedDomain, setSelectedDomain] = useState('');
  const [selectedPublisherDomain, setSelectedPublisherDomain] = useState('');
  const [tnc, setTnc] = useState("");
  const [isProcessing, setIsProcessing] = useState(false)
  const [comments, setComments] = useState("");
  const [formData, setFormData] = useState({});
  const [selectedCountry, setSelectedCountry] = useState('');
  const [otherCountry, setOtherCountry] = useState('');
  const [selectedPublisherCountry, setSelectedPublisherCountry] = useState('');
  const [otherPublisherCountry, setOtherPublisherCountry] = useState('');
  const [isActive, setIsActive] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const contractId = location.pathname.split('/')[2];
  const userType = sessionStorage.getItem('role');

  useEffect(() => {
    if (contractId === undefined) {
      return;
    }
    axios.get(`/api/contract/get/one/${contractId}`, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('Token')
      }
    })
      .then((response) => response.data)
      .then((data) => {
        data.startDate = dateFormat(data.startDate)
        data.endDate = dateFormat(data.endDate)
        setSelectedCountry(data.country)
        setSelectedPublisherCountry(data.pubCountry)
        setIsActive(data.isActive);
        setFormData(data);
        setTnc(data.tnc)
        setComments(data.comments)
        populatePublisherFormFields(data);
        populateFormFields(data);
      })
      .catch((error) => {
        handleResponse(error, navigate);
        console.log(error);
      }
      );

  }, [contractId]);

  const populatePublisherFormFields = (data) => {
    Object.keys(data).forEach((fieldName) => {
      const field = publisherDomains.flatMap((domain) => domain.fields).find((f) => f.name === fieldName);
      if (field) {
        setValue(fieldName, data[fieldName]);
      }
    });
  };
  const populateFormFields = (data) => {
    Object.keys(data).forEach((fieldName) => {
      const field = domains.flatMap((domain) => domain.fields).find((f) => f.name === fieldName);
      if (field) {
        setValue(fieldName, data[fieldName]);
      }
    });
  };

  useEffect(() => {

    setValue('tnc', tnc);
  }, [tnc, setValue]);

  useEffect(() => {

    setValue('comments', comments);
  }, [comments, setValue]);

  const domains = [
    {
      name: 'General Info',
      fields: [
        { label: 'Company Name', type: 'text', name: 'companyName', Disabled: 'true' },
        { label: 'Street Address', type: 'text', name: 'streetAddress', Disabled: 'true' },
        { label: 'City/State/Zip', type: 'text', name: 'cityStateZip', Disabled: 'true' },
        { label: 'Country', type: 'text', name: 'country', Disabled: 'true' },
        { label: 'Pan Number', type: 'text', name: 'panNumber', Disabled: 'true' },
        { label: 'CIN Number', type: 'text', name: 'cinNumber', Disabled: 'true' },
        { label: 'Goods & Service Tax No.', type: 'text', name: 'gstNumber', Disabled: 'true' },
        { label: 'URL', type: 'text', name: 'url', Disabled: 'true' },
      ],
    },
    {
      name: 'Contact Info',
      fields: [
        { label: 'Sales Contact Name', type: 'text', name: 'salesContactName', Disabled: 'true' },
        { label: 'Sales Contact Email', type: 'email', name: 'salesContactEmail', Disabled: 'true' },
        { label: 'Sales Contact Phone', type: 'tel', name: 'salesContactPhone', Disabled: 'true' },
        { label: 'Finance Contact Name', type: 'text', name: 'financeContactName', Disabled: 'true' },
        { label: 'Finance Contact Email', type: 'email', name: 'financeContactEmail', Disabled: 'true' },
        { label: 'Finance Contact Phone', type: 'tel', name: 'financeContactPhone', Disabled: 'true' },
      ],
    }]

  const publisherDomains = [
    {
      name: 'General Info',
      fields: [
        { label: 'Company Name', type: 'text', name: 'pubCompanyName', Disabled: !isActive },
        { label: 'Street Address', type: 'text', name: 'pubStreetAddress', Disabled: !isActive },
        { label: 'City/State/Zip', type: 'text', name: 'pubCityStateZip', Disabled: !isActive },
        { label: 'Country', type: 'text', name: 'pubCountry', Disabled: !isActive },
        { label: 'Pan Number', type: 'text', name: 'pubPanNumber', Disabled: !isActive, isDisabled: selectedPublisherCountry === 'India' ? false : true },
        { label: 'CIN Number', type: 'text', name: 'pubCinNumber', isDisabled: selectedPublisherCountry === 'India' ? false : true, Disabled: !isActive },
        { label: 'Goods & Service Tax No.', type: 'text', name: 'pubGstNumber', isDisabled: selectedPublisherCountry === 'India' ? false : true, Disabled: !isActive },
        { label: 'TAX / VAT NO.', type: 'text', name: 'pubTaxID', isDisabled: selectedPublisherCountry === 'India' ? true : false, Disabled: !isActive },
        { label: 'URL', type: 'text', name: 'pubUrl', Disabled: !isActive },
      ],
    },
    {
      name: 'Contact Info',
      fields: [
        { label: 'Sales Contact Name', type: 'text', name: 'pubSalesContactName', Disabled: !isActive },
        { label: 'Sales Contact Email', type: 'email', name: 'pubSalesContactEmail', Disabled: !isActive },
        { label: 'Sales Contact Phone', type: 'tel', name: 'pubSalesContactPhone', Disabled: !isActive },
        { label: 'Finance Contact Name', type: 'text', name: 'pubFinanceContactName', Disabled: !isActive },
        { label: 'Finance Contact Email', type: 'email', name: 'pubFinanceContactEmail', Disabled: !isActive },
        { label: 'Finance Contact Phone', type: 'tel', name: 'pubFinanceContactPhone', Disabled: !isActive },
      ],
    },
    {
      name: 'Campaign Info',
      fields: [
        { label: 'Description', type: 'text', name: 'description', Disabled: 'true' },
        { label: 'Start Date', type: 'date', name: 'startDate', Disabled: 'true' },
        { label: 'End Date', type: 'date', name: 'endDate', Disabled: 'true' },
        { label: 'Daily Limit', type: 'text', name: 'dailyLimit', Disabled: 'true' },
        { label: 'Ad Sizes', type: 'text', name: 'adSizes', Disabled: 'true' },
        { label: 'Geo Targeting', type: 'text', name: 'geoTargeting', Disabled: 'true' },
        { label: 'URL', type: 'text', name: 'campaignUrl', Disabled: 'true' },
      ],
    },
    {
      name: 'Terms',
      fields: [
        { label: 'Deal Type', type: 'text', name: 'dealType', Disabled: 'true' },
        { label: 'Rate', type: 'text', name: 'rate', Disabled: 'true' },
        { label: 'Billing Source', type: 'text', name: 'billingSource', Disabled: 'true' },
        { label: 'Payment Terms', type: 'text', name: 'paymentTerms', Disabled: 'true' },
        { label: 'Cancellation Period', type: 'text', name: 'period', Disabled: 'true' },
        { label: 'Total Budget', type: 'text', name: 'totalBudget', Disabled: 'true' },
      ],
    },
  ];


  const togglePublisherDomain = (domainName) => {
    if (selectedPublisherDomain === domainName) {
      setSelectedPublisherDomain(null);
    } else {
      setSelectedPublisherDomain(domainName);
    }
  };

  const toggleDomain = (domainName) => {
    if (selectedDomain === domainName) {
      setSelectedDomain(null);
    } else {
      setSelectedDomain(domainName);
    }
  };

  const handleCountryChange = (event) => {
    event.preventDefault();
    setOtherCountry('')
    setSelectedCountry(event.target.value);
  };
  const handlePublisherCountryChange = (event) => {
    event.preventDefault();
    setOtherPublisherCountry('')
    setSelectedPublisherCountry(event.target.value);
  };


  const onSubmit = (data) => {
    console.log(data);
    setIsProcessing(true)
    data.isSubmitted = true
    let isEmpty = false;
    const domains = Object.keys(data);
    console.log(domains);
    isEmpty = domains.some((domain) => {
      const fields = data[domain];
      if(data.pubCompanyName === undefined || data.pubStreetAddress === undefined || data.pubCityStateZip === undefined || data.pubUrl === undefined || data.pubSalesContactName === undefined || data.pubSalesContactEmail === undefined || data.pubSalesContactPhone === undefined || data.pubFinanceContactName === undefined || data.pubFinanceContactEmail === undefined || data.pubFinanceContactPhone === undefined ){
        return true;
      }
      if (selectedPublisherCountry === "India" && (domain === "pubTaxID" || domain === "pubCinNumber" || domain === "pubGstNumber" || domain === "comments") && fields.length === 0) {
        if(data.pubPanNumber === undefined){
          return true;
        }
        if (data.pubTaxID.length !== null) {
          data.pubTaxID = ' ';
        }
        if (data.pubCinNumber.length === null) {
          data.pubCinNumber = ' ';
        }
        if (data.pubGstNumber.length === null) {
          data.pubGstNumber = ' ';
        }
        return false;
      }
      if (selectedPublisherCountry !== "India" && (domain === "pubTaxID" || domain === "pubPanNumber" || domain === "pubCinNumber" || domain === "pubGstNumber" || domain === "comments") && fields.length === 0) {
        if(data.pubTaxID === undefined){
          return true;
        }
        if (data.pubPanNumber.length === null || data.pubPanNumber.length !== null) {
          data.pubPanNumber = ' ';
        }
        if (data.pubCinNumber.length === null || data.pubCinNumber.length !== null) {
          data.pubCinNumber = ' ';
        }
        if (data.pubGstNumber.length === null || data.pubGstNumber.length !== null) {
          data.pubGstNumber = ' ';
        }
        if (data.pubTaxID.length === null) {
          data.pubTaxID = ' ';
        }
        return false;
      }
      return fields.length === 0;
    });

    console.log(isEmpty);

    if (isEmpty) {
      showErrorToast("Error: Required fields not Filled!");
      setIsProcessing(false);
      return;
    }

    axios
      .put(`/api/contract/get/one/${contractId}`, data, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('Token')
        }
      })
      .then((response) => {
        showSuccessToast(response.data && response.data.Message ? response.data.Message : "Success");
      })
      .catch((err) => {
        console.log(err);
        setIsProcessing(false);
        showErrorToast("Registration Error!");
      })
      .finally(() => {
        setIsProcessing(false); 
        navigate('/form');
      });
  };

  if (userType === 'Publisher') {
    return (
      <>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" max-w-3xl mx-auto p-6 bg-gray-100 mt-12 rounded shadow-md animate-slide-in-2"
        >
          <h1 className='flex justify-center text-3xl mb-2 font-medium'>Advertiser </h1>
          {domains.map((domain, index) => (
            <div
              key={domain.name}
              className={`mb-4 p-6 border rounded transition-colors duration-300 ease-in-out ${selectedDomain === domain.name
                ? "border-blue-500"
                : "border-gray-300"
                }`}
            >
              <div
                className="flex justify-between items-center cursor-pointer transition-colors duration-300 ease-in-out"
                onClick={() => toggleDomain(domain.name)}
              >
                <h2 className="text-xl font-bold">{domain.name}</h2>
                {selectedDomain === domain.name ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-blue-500 transition-transform duration-300 ease-in-out transform rotate-180"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15.293 7.293a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414l-2.5-2.5-2.5 2.5a1 1 0 1 1-1.414-1.414l3-3a1 1 0 0 1 1.414 0zM5 11a1 1 0 0 0-1"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-500 transition-transform duration-300 ease-in-out transform rotate-0"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 3a1 1 0 0 1 1 1v5h5a1 1 0 0 1 0 2h-5v5a1 1 0 0 1-2 0v-5H4a1 1 0 1 1 0-2h5V4a1 1 0 0 1 1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </div>
              {selectedDomain === domain.name && (
                <div className="mt-4 transition-all duration-300 ease-in-out">
                  {domain.fields.map((field) => (
                    <div
                      style={{
                        display: field.isDisabled ? "none" : "block"
                      }
                      }
                      className="mb-4" key={field.name}>
                      {field.name === 'country' && (
                        <div>
                          <label htmlFor={field.name} className="block font-medium mb-2">
                            {field.label}:
                          </label>
                          <select
                            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                            id={field.name}
                            {...register(field.name)}
                            value={selectedCountry} onChange={handleCountryChange}
                            disabled={field.Disabled}
                            required>
                            {countries.map((country) => (
                              <option key={country.name} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      {field.name !== 'country' && (
                        <div>
                          <label htmlFor={field.name} className="block font-medium mb-2">
                            {field.label}:
                          </label>
                          <input
                            type={field.type}
                            disabled={field.Disabled}
                            id={field.name}
                            {...register(field.name)}
                            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                            required
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </form>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="max-w-3xl mx-auto p-6 bg-gray-100 mt-12 rounded shadow-md animate-slide-in-2"
        >
          <h1 className='flex justify-center text-3xl mb-2 font-medium'>Publisher </h1>
          {publisherDomains.map((domain, index) => (
            <div
              key={domain.name}
              className={`mb-4 p-6 border rounded transition-colors duration-300 ease-in-out ${selectedPublisherDomain === domain.name
                ? "border-blue-500"
                : "border-gray-300"
                }`}
            >
              <div
                className="flex justify-between items-center cursor-pointer transition-colors duration-300 ease-in-out"
                onClick={() => togglePublisherDomain(domain.name)}
              >
                <h2 className="text-xl font-bold">{domain.name}</h2>
                {selectedPublisherDomain === domain.name ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-blue-500 transition-transform duration-300 ease-in-out transform rotate-180"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15.293 7.293a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414l-2.5-2.5-2.5 2.5a1 1 0 1 1-1.414-1.414l3-3a1 1 0 0 1 1.414 0zM5 11a1 1 0 0 0-1"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-500 transition-transform duration-300 ease-in-out transform rotate-0"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 3a1 1 0 0 1 1 1v5h5a1 1 0 0 1 0 2h-5v5a1 1 0 0 1-2 0v-5H4a1 1 0 1 1 0-2h5V4a1 1 0 0 1 1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </div>
              {selectedPublisherDomain === domain.name && (
                <div className="mt-4 transition-all duration-300 ease-in-out">
                  {domain.fields.map((field) => (
                    <div
                      style={{
                        display: field.isDisabled ? "none" : "block"
                      }
                      }
                      className="mb-4" key={field.name}>
                      {field.name === 'pubCountry' && (
                        <div>
                          <label htmlFor={field.name} className="block font-medium mb-2">
                            {field.label}:
                          </label>
                          <select
                            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                            id={field.name}
                            {...register(field.name)}
                            value={selectedPublisherCountry}
                            onChange={handlePublisherCountryChange}
                            disabled={field.Disabled}
                            required>
                            {countries.map((country) => (
                              <option key={country.name} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      {(field.name !== 'pubCountry' && field.name === 'pubPanNumber') && (
                        <div>
                          <label htmlFor={field.name} className="block font-medium mb-2">
                            {field.label}:
                          </label>
                          <input
                            type={field.type}
                            disabled={field.Disabled}
                            id={field.name}
                            {...register(field.name)}
                            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                            required={selectedPublisherCountry === 'India'}
                          />
                        </div>
                      )}
                      {((field.name !== 'pubCountry' && field.name !== 'pubPanNumber'&& (field.name === 'pubCinNumber' || field.name === 'pubGstNumber'))) && (
                        <div>
                          <label htmlFor={field.name} className="block font-medium mb-2">
                            {field.label}:
                          </label>
                          <input
                            type={field.type}
                            disabled={field.Disabled}
                            id={field.name}
                            {...register(field.name)}
                            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                            // required={selectedPublisherCountry === 'India'}
                          />
                        </div>
                      )}
                      {(field.name !== 'pubCountry' && field.name === 'pubTaxID') && (
                        <div>
                          <label htmlFor={field.name} className="block font-medium mb-2">
                            {field.label}:
                          </label>
                          <input
                            type={field.type}
                            disabled={field.Disabled}
                            id={field.name}
                            {...register(field.name)}
                            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                            // required={selectedPublisherCountry !== 'India'}
                          />
                        </div>
                      )}
                      {(field.name !== 'pubCountry' && field.name !== 'pubPanNumber' && field.name !== 'pubCinNumber' && field.name !== 'pubGstNumber' && field.name !== 'pubTaxID') && (
                        <div>
                          <label htmlFor={field.name} className="block font-medium mb-2">
                            {field.label}:
                          </label>
                          <input
                            type={field.type}
                            disabled={field.Disabled}
                            id={field.name}
                            {...register(field.name)}
                            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                            required
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}

          <div className="mb-4 p-6 border rounded transition-colors duration-300 ease-in-out border-blue-500">
            <h2 className="text-xl font-bold mb-4">Terms and Conditions</h2>

            <div className="mt-2">
              <textarea
                id="tnc"
                {...register('tnc')}
                className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                placeholder="Enter T&C"
                value={tnc}
                onChange={(e) => setTnc(e.target.value)}
                rows={tnc ? Math.ceil(tnc.length / 100) : 1}
                disabled
              ></textarea>
            </div>
          </div>

          <div className="mb-4 p-6 border rounded transition-colors duration-300 ease-in-out border-blue-500">
            <label>
              Any Comments
            </label>
            <div className="mt-2">
              <textarea
                id="comments"
                {...register("comments")}
                className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                placeholder="Enter comments"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                disabled={!isActive}
              ></textarea>
            </div>
          </div>

          {isActive && isActive === true ? (
            <button
              type="submit"
              disabled={isProcessing}
              className={`${isProcessing ? "bg-gray-500 hover:bg-gray-700" : "bg-blue-500 hover:bg-blue-700"} flex mx-auto  text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out`}
            >
              Submit
            </button>
          ) : (
            <button
              type="submit"
              className="flex mx-auto bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out"
              disabled
            >
              Submitted
            </button>
          )}
        </form>
      </>
    );
  }
};

export default Form;