import React from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

function Protected({ isSignedIn, userType, path, children }) {
  const Navigate = useNavigate();
  if (!isSignedIn) {
    return <Navigate to="/" replace />
  }
  (async () => {

    const signinStatus = sessionStorage.getItem('isSignedIn');

    if (signinStatus === "false") {
      return Navigate('/')

    };
    try {
      const response = await axios.get(`/api/auth/checkstatus`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('Token'),
          'role': sessionStorage.getItem('role')
        }
      });
      if (response.data.role !== sessionStorage.getItem('role')) {
        sessionStorage.clear();
        Navigate('/')
      }
      return response;
    } catch (error) {

      console.log(error);
    }
  })();

  return children
}

export default Protected

