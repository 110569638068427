import React from 'react';
import { useStateContext } from "../contexts/ContextProvider";
import { useNavigate } from 'react-router-dom';

const TopCard = (props) => {
  const { title, figure, icon, link, index } = props;
  const navigate = useNavigate();
  const { currentColor } = useStateContext();

  const handleClick = (e, link) => {
    e.preventDefault();
    navigate(`/${link}`);
  };

  return (
    <div
      key={index}
      className="w-full lg:w-6/12 xl:w-3/12 px-4 hover:scale-105 transform transition duration-300 cursor-pointer"
      onClick={(e) => handleClick(e, link)}
    >
      <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
        <div className="flex-auto p-4">
          <div className="flex flex-wrap">
            <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
              <h5 className="text-blueGray-400 uppercase font-bold text-xs">{ title}</h5>
              <span className="font-semibold text-xl text-blueGray-700">{figure}</span>
            </div>
            <div className="relative w-auto pl-4 flex-initial">
              <div
                style={{ backgroundColor: currentColor }}
                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 text-2xl shadow-lg rounded-full"
              >
                {icon}
              </div>
            </div>
          </div>
          <p className="text-sm text-blueGray-400 mt-4">
            <span className="text-emerald-500 mr-2">
              <i className="fas fa-arrow-up"></i> Data:
            </span>
            <span className="whitespace-nowrap">Since Start</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopCard;
