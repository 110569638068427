import React from 'react'
import { useLocation } from 'react-router-dom';
import SalesForm from "./Forms/salesShowForm";
import SalesTableView from './Forms/SalesTableView'

const EditContract = () => {
  const location = useLocation();
  const salesId = location.pathname.split('/')[2]
  const editMode = location.pathname.endsWith('edit')

  const userType = sessionStorage.getItem('role') === 'Employee' || 'Admin' || 'Finance';

  return (
    editMode ? (
      < SalesForm salesId={salesId} />
    ) :
      < div >
        {salesId && !userType && (
          <div>
            <SalesForm salesId={salesId} />
          </div>
        )}
        {
          salesId && userType && (
            <div>
              <SalesTableView salesId={salesId} />
            </div>
          )
        }
      </ div>
  )
}

export default EditContract