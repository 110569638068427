import React from 'react'

const CompanyCard = (props) => {
    const title = props.title;
    const color = props.color;
    const icon = props.icon;

    return (
        <div className='mb-4'>
            <div className={` block max-w-s p-6 bg-${color} border border-gray-200 rounded-xl shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`}>
                <div className='flex justify-start'>
                    <img
                        src={icon}
                        alt="company"
                    />
                    <h5 className={`p-2 px-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white`}>{title} </h5>
                </div>
            </div>
        </div>
    )
}

export default CompanyCard

// Noteworthy technology acquisitions 2021 

// Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.