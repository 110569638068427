import React, { useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import Form from "../pages/Forms/salesForm";
import Modal from "./modal";
import { SalesContext } from "../pages/Sales"

const NewSalesModal = () => {
    const { modal, setModal } = useContext(SalesContext);

    const closeModal = () => {
        setModal(false);
    };

    return (
        <div className="mb-6">
            <Modal isOpen={modal} onClose={closeModal} className="mt-12 ">
                <div className="flex items-center justify-center">
                    <div className="bg-white w-full max-w-3xl mx-auto p-8 rounded-lg shadow-lg">
                        <h3 className="text-xl font-bold mb-6 text-center rounded-lg">Create New Advertiser</h3>
                        <Form
                        />
                        <button
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                            onClick={closeModal}
                        >
                            <svg
                                className="w-6 h-6 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                            >
                                <path d="M13.41 12l4.29-4.29a1 1 0 1 0-1.41-1.41L12 10.59 7.71 6.29a1 1 0 0 0-1.41 1.41L10.59 12l-4.3 4.29a1 1 0 0 0 1.42 1.42L12 13.41l4.29 4.3a1 1 0 0 0 1.42-1.42L13.41 12z" />
                            </svg>
                        </button>

                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default NewSalesModal;
