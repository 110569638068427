import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../components"; 
import axios from 'axios';
import Modal from "../modals/addCompany";
import { useStateContext } from "../contexts/ContextProvider";
import CompanyCard from "../cards/CompanyCard";
import companyimg from '../assets/company.png';
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import companyAdd from "../assets/company-add.png"
import companyAddLight from "../assets/company-add-light.png";
import HashLoader from "react-spinners/HashLoader";
import { showErrorToast } from "../Utils/toastUtils";
import handleResponse from '../Utils/responseHandler';

const Companies = () => {

  const {
    currentColor,
  } = useStateContext();

  const [companies, setCompanies] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const userType = sessionStorage.getItem('role');
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await axios.get('/api/company/all', {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('Token')
        }
      })
      setCompanies(result.data)

    } catch (error) {
      showErrorToast("Error!");
      handleResponse(error, navigate);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  },[])

  const openModalHandler = () => {
    setModalOpen(true);
  };

  if (userType !== 'Employee' || 'Publisher') {
    return (
      <>
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
          <Header category="Page" title="Companies" />
          {loading ? (
            <div class="flex justify-center m-3">
              <HashLoader color={currentColor} />
            </div>
          ) : (

            <div className="flex mt-20 px-5 flex-wrap justify-start w-100 animate-slide-out">
              {companies.map((company) => (
                <TooltipComponent
                  content={company.companyName}
                  position="TopCenter"
                  key={company._id}
                >
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="mr-5 hover:scale-105 transform transition duration-300 cursor-pointer"
                  >
                    <div onClick={() => navigate(`/company/${company._id}`)}>
                      <CompanyCard
                        title={company.companyName}
                        figure="130"
                        color="yellow"
                        icon={companyimg}
                      />
                    </div>

                  </div>
                </TooltipComponent>
              ))}

              {userType && userType === 'Admin' ? (
              <button onClick={openModalHandler}>
                {localStorage.getItem('themeMode') === 'Dark' ? (
                  <CompanyCard
                    title="Add Company"
                    figure="130"
                    color="gray-50"
                    icon={companyAdd}
                  />) : (
                  <CompanyCard
                    title="Add Company"
                    figure="130"
                    color="gray-50"
                    icon={companyAddLight}
                  />
                )}
              </button>
              ) : (<></>)}
            </div>
          )}
        </div>
        <Modal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          fetchData={fetchData} />
      </>
    );
  }
};
export default Companies;
