import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Header } from "../components";
import Form from "./Forms/CompanyDetailForm";
import HashLoader from "react-spinners/HashLoader";
import { useStateContext } from "../contexts/ContextProvider";
const CompanyDetails = () => {
  const [companyData, setCompanyData] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const { currentColor } = useStateContext();

  let { id } = useParams();

  const fetchData = async () => {
    try {
      setLoading(true)
      const result = await axios.get(
        `/api/company/get/one/${id}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('Token')
        }
      }
      );
      setCompanyId(result.data._id)
      setCompanyData(result.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {companyData ? (
        <>
          <Header category="Page" title={companyData.companyName} />

          <h2>ID: {companyData._id}</h2>
          <h2>Company: {companyData.companyName}</h2>
        </>
      ) : (
        <>
          <Header category="Page" title="Publisher Info" />
          {
            loading && (
              <div class="flex justify-center m-3">
                <HashLoader color={currentColor} />
              </div>
            )
          }
        </>
      )}

      {companyId &&
        <Form
          companyId={companyId}
          fetchData={fetchData}
        />}

    </div>

  );
};

export default CompanyDetails;