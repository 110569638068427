import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Header } from "../components";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { ReactComponent as ImageIcon } from "../assets/image.svg";
import { ReactComponent as PdfIcon } from "../assets/pdf.svg";
import { ReactComponent as WordIcon } from "../assets/word.svg";
import { ReactComponent as ExcelIcon } from "../assets/excel.svg";
import cloudSvg from "../assets/cloud.svg";
import dropFile from "../assets/dropFile.png";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { showSuccessToast, showErrorToast } from "../Utils/toastUtils";
import Modal from "../modals/modal";
import HashLoader from "react-spinners/HashLoader";
import { useStateContext } from "../contexts/ContextProvider";

const SalesDocuments = () => {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [openFileId, setOpenFileId] = useState(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const { currentColor } = useStateContext();
  const [loading, setLoading] = React.useState(false);
  const [limit, setLimit] = React.useState(0);
  const [used, setUsed] = React.useState(0);

  let { id } = useParams();

  // Fetch all files when component mounts
  useEffect(() => {
    fetchFiles();
  }, [selectedFile]);


  const fetchFiles = () => {
    try {
      setLoading(true);
      axios
        .get(`/api/file/${id}`, {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('Token')
          }
        })
        .then((response) => {
          setFiles(response.data.docs);
          setLimit(response.data.limit)
          setUsed(response.data.used)
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      showErrorToast(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.body.style.overflow = "auto";
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (selectedFile) {
      setShowModal(true);
    }
  }, [selectedFile]);

  const setUploadFile = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
    setFileName(acceptedFiles[0].name);
  };

  // Handle file selection using onDrop prop on Dropzone
  const onDrop = (acceptedFiles) => {
    setUploadFile(acceptedFiles);
  };

  const getFileIcon = (fileType) => {
    const iconStyle = { height: "50px", width: "50px" }; // Define the desired icon size here

    if (fileType.startsWith("image/")) {
      return <ImageIcon style={iconStyle} />;
    } else if (fileType === "application/pdf") {
      return <PdfIcon style={iconStyle} />;
    } else if (
      fileType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType === "application/msword"
    ) {
      return <WordIcon style={iconStyle} />;
    } else if (
      fileType ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      fileType === "application/vnd.ms-excel"
    ) {
      return <ExcelIcon style={iconStyle} />;
    } else {
      return null;
    }
  };

  // Handle file upload using selectedFile state instead of relying on event.target.files
  const handleUploadSubmit = async () => {
    try {
      if (selectedFile) {
        let formData = new FormData();
        formData.append("file", selectedFile);
        if ((Math.round((selectedFile.size) * 100 / (1024 * 1024)) / 100) > 15) {
          return showErrorToast("File Size greater than 15 MB")
        }

        await axios.post(`/api/file/sales/${id}`, formData, {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('Token')
          }
        })
        setSelectedFile(null);
        setShowModal(false);
        showSuccessToast("File uploaded successfully!");
      } else {
        showErrorToast("File Empty!");
        setSelectedFile(null);
        setShowModal(false);
      }
    } catch (error) {
      console.error(error);
      showErrorToast("Error uploading file");
    }
  };

  const handleDeleteAction = (fileId) => {
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this file?"
      );
      if (confirmDelete) {
        axios
          .delete(`/api/file/${fileId}`, {
            headers: {
              Authorization: 'Bearer ' + sessionStorage.getItem('Token')
            }
          })
          .then((response) => {
            showSuccessToast(response.data.Message);
            fetchFiles();
          })
          .catch((error) => {
            showErrorToast(error);
            console.log(error);
          });
      } else {
        showErrorToast("File deletion cancelled");
      }
    } catch {
      showErrorToast("File deletion cancelled");
    } finally {
      closeContextMenu()

    }
  };

  const handleOpenAction = (event, fileId) => {
    event.preventDefault();
    setCursorPosition({ x: event.clientX, y: event.clientY });
    document.body.style.overflow = "hidden";
    setOpenFileId(fileId);
  };

  const closeContextMenu = () => {
    setCursorPosition({ x: 0, y: 0 });
    document.body.style.overflow = "auto";
    setOpenFileId(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl animate-slide-in-2">
        <Header category="Page" title="Sales Documents" />
        <div className="flex relative flex-col">
          <div className="flex flex-row my-3 justify-between">
            <div className="text-2xl font-bold">My Files</div>
            <div className="text-xl font-bold">{Math.round(used * 100 / 1024) / 100}/{Math.round(limit * 100 / 1024) / 100} MB</div>
          </div>


          {loading ? (
            <div class="flex justify-center m-3">
              <HashLoader color={currentColor} />
            </div>
          ) : (
            <div
              {...getRootProps()}
              className={`dropzone ${isDragActive ? "active bg-blue-200" : "bg-white"
                } border-solid border-2 border-sky-500 h-full  py-10 mb-10 rounded-lg`}
            >
              <input {...getInputProps()} />

              <div className="flex flex-wrap p-1 mb-10">
                {files.map((file) => (
                  <TooltipComponent
                    content={file.name}
                    position="TopCenter"
                    key={file.id}
                  >
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className="flex flex-grow items-center m-3 bg-gray-100 rounded p-2 w-50 "
                    >

                      <div className="m-2">{getFileIcon(file.type)}</div>

                      <a
                        href={`https://crmdocs.adsolut.in/${file.name}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="w-[10rem] truncate overflow-hidden">
                          {file.name}
                        </div>
                      </a>

                      <div className="ml-auto">
                        <button
                          className="bg-gray-100 z-100 rounded-full flex justify-center items-center p-2"
                          onClick={(event) => handleOpenAction(event, file._id)}
                        >
                          <svg
                            className="w-5 h-5 fill-current "
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                            id="IconChangeColor"
                          >
                            {" "}
                            <path
                              d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                              id="mainIconPathAttribute"
                            ></path>{" "}
                          </svg>



                        </button>
                      </div>
                    </div>
                  </TooltipComponent>
                ))}
              </div>

              {isDragActive ? (
                <>
                  <div className="flex justify-center h-96 w-96 sm:h-98 sm:w-98 items-center"></div>
                  <div className="flex justify-center items-center">
                    <div className="fixed  bottom-4">
                      <div className="animate-slide-in-3   flex justify-center items-center flex-col">
                        <img
                          src={cloudSvg}
                          alt="Cloud"
                          className="h-25 w-25 sm:h-24 sm:w-24  animate-bounce-custom"
                        />
                        <div className="bg-blue-500 py-5 px-24 text-lg  items-center text-white rounded-lg">
                          Drop here
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex justify-center items-center">
                  <div className="h-96 w-96 sm:h-98 sm:w-98 overflow-hidden">
                    <img
                      src={dropFile}
                      alt="Cloud"
                      className="h-full w-full m-auto"
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
            <div className="flex items-center justify-center">
              <div className="bg-white w-full max-w-3xl mx-auto p-12 rounded-lg shadow-lg">
                <h2>Confirm File Upload</h2>
                <p>Do you want to upload {fileName}?</p>
                <div className="modal-buttons flex justify-center">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white mx-5 mt-10 font-bold py-2 px-4 rounded"
                    onClick={handleUploadSubmit}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white mx-5 mt-10 font-bold py-2 px-4 rounded"
                    onClick={() => {
                      setShowModal(false);
                      setSelectedFile(null);
                    }}
                  >
                    No
                  </button>
                </div>

                <button
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                  onClick={() => {
                    setSelectedFile(null);
                    setShowModal(false);
                  }}
                >
                  <svg
                    className="w-6 h-6 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M13.41 12l4.29-4.29a1 1 0 1 0-1.41-1.41L12 10.59 7.71 6.29a1 1 0 0 0-1.41 1.41L10.59 12l-4.3 4.29a1 1 0 0 0 1.42 1.42L12 13.41l4.29 4.3a1 1 0 0 0 1.42-1.42L13.41 12z" />
                  </svg>
                </button>
              </div>
            </div>
          </Modal>

          {openFileId && (
            <div
              className="fixed z-10"
              style={{
                top: cursorPosition.y,
                left: cursorPosition.x,
              }}
            >
              <div className="bg-white w-48 rounded-lg shadow-lg relative">
                <div className="flex flex-col ">
                  <button
                    className="flex items-center justify-start mt-2 pr-2 text-gray-500 hover:text-gray-700"
                    onClick={() => {
                      closeContextMenu();
                    }}
                  >
                    <svg
                      className="w-6 h-6 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M13.41 12l4.29-4.29a1 1 0 1 0-1.41-1.41L12 10.59 7.71 6.29a1 1 0 0 0-1.41 1.41L10.59 12l-4.3 4.29a1 1 0 0 0 1.42 1.42L12 13.41l4.29 4.3a1 1 0 0 0 1.42-1.42L13.41 12z" />
                    </svg>
                  </button>
                  <button
                    className="text-left px-4 py-2 hover:bg-gray-100"
                    onClick={() => handleDeleteAction(openFileId)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SalesDocuments;

