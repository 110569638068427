import React, { useState, useRef } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from 'axios';
import { showSuccessToast, showErrorToast } from "../../Utils/toastUtils";
import { countries } from "../../data/dummy";
import ReCAPTCHA from "react-google-recaptcha";

const UserLogin = (props) => {
    const navigate = useNavigate();
    const { register, handleSubmit, reset } = useForm();
    const [userInfo, setUserInfo] = useState(false)
    const [uniqueID, setUniqueID] = useState("");
    const signin = props.signin;
    const [selectedCountry, setSelectedCountry] = useState('India');
    const [otherCountry, setOtherCountry] = useState('');
    const captchaRef = useRef(null)

    const handleCountryChange = (event) => {
        event.preventDefault();
        setOtherCountry('')
        setSelectedCountry(event.target.value);
    };

    const submit = async (data) => {

        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        if(token === ''){
            showErrorToast('Complete Captcha');
            return;
        }
        data.captchaToken = token

        axios
            .post("/api/auth/login", data)
            .then((res) => {
                console.log(res);
                const tokenToSet = res.data ? res.data.token : "";
                if(tokenToSet === undefined){
                    showErrorToast(res.data.error.Message);
                    return;
                }
                console.log(tokenToSet)
                sessionStorage.setItem(
                    "Token",
                    tokenToSet.substring(7, tokenToSet.length)
                );
                sessionStorage.setItem("isLoggedIn", true);
                sessionStorage.setItem("role", res && res.data ? res.data.role : "");
                sessionStorage.setItem("UserDetails", res && res.data ? JSON.stringify(res.data.profile) : {});
                setUniqueID(res && res.data ? res.data.uuid : "")
                if (res.data.isNew) {
                    signin();
                    setUserInfo(true)
                    sessionStorage.setItem("newUser", true);
                    showSuccessToast("Welcome to Adsolut CRM")
                } else {
                    signin();
                    navigate("/dashboard");
                    reset();
                }
            })
            .catch((err) => {
                navigate("/");
            });
    };

    const submit2 = (data) => {
        data.uuid = uniqueID;
        axios
            .post("/api/employee/createbasic", data)
            .then((res) => {
                sessionStorage.setItem("UserDetails", res && res.data ? JSON.stringify(res.data.profile) : "");

                setUniqueID(res && res.data ? res.data.uuid : "")
                showSuccessToast("Basic Information Updated")
                navigate("/dashboard");

            })
            .catch((err) => {
                showErrorToast("Error in updating information")
                navigate("/");

            });
        reset();
        sessionStorage.setItem("isLoggedIn", true);
        sessionStorage.setItem("isSignedIn", true);

    };

    return (
        <section className="bg-gray-50 dark:bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 animate-slide-in-2">
                <a className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img className="-m:4 w-96" src="https://cdn.playstream.media/crm-adsolut.png" alt=" Logo" />
                </a>
                {!userInfo ?
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 animate-slide-in-1 ">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Login in to your account
                            </h1>
                            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(submit)}>
                                <div>
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                    <input type="text"
                                        name="email"
                                        id="email"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com"
                                        {...register("emailId")}
                                        required />
                                </div>
                                <div>
                                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input type="password"
                                        name="password"
                                        id="password"
                                        placeholder="••••••••"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        {...register("password")}
                                        required />
                                </div>

                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    ref={captchaRef}
                                />
                                <button
                                    type="submit"
                                    className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-secondary-600 dark:hover:bg-secondary-700 dark:focus:ring-blue-800"
                                >
                                    Log in
                                </button>


                            </form>

                            <div className="flex items-center justify-end">

                                <Link to="/forgot-password" className="text-sm text-primary-500 font-medium text-primary-600 hover:underline dark:text-white">Forgot Password?</Link>
                            </div>

                        </div>
                    </div> :
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 animate-slide-in">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Basic Information
                            </h1>
                            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(submit2)}>
                                <div className="flex space-x-4">
                                    <label htmlFor="empCountry" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Country
                                    </label>
                                    <select
                                        className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        id="empCountry"
                                        {...register("empCountry")}
                                        value={selectedCountry}
                                        onChange={handleCountryChange}
                                        defaultValue={'India'}
                                        required>
                                        {countries.map((country) => (
                                            <option key={country.name} value={country.name}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex space-x-4">

                                    <div className='w-1/2'>
                                        <label htmlFor="firstname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Your First Name
                                        </label>
                                        <input type="text"
                                            name="First Name"
                                            id="firstName"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="First Name"
                                            {...register("firstName")}
                                            required />
                                    </div>
                                    <div className='w-1/2'>
                                        <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Your Last Name
                                        </label>
                                        <input type="text"
                                            name="Last Name"
                                            id="lastName"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Last Name"
                                            {...register("lastName")}
                                            required />
                                    </div>
                                </div>
                                <div className="flex space-x-4">

                                    {selectedCountry && selectedCountry === 'India' ? (
                                        <>
                                            <div className='w-1/2'>
                                                <label htmlFor="pancard" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                    Your Pan Card Number
                                                </label>
                                                <input
                                                    type="text"
                                                    name="Pan Number"
                                                    id="panCard"
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="XXXPX1111X"
                                                    {...register("pan")}

                                                />
                                            </div>
                                            <div className='w-1/2'>
                                                <label htmlFor="aadharnumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                    Your Aadhar Card Number
                                                </label>
                                                <input
                                                    type="text"
                                                    name="Aadhar Number"
                                                    id="aadharNumber"
                                                    pattern="[0-9]{12}"
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    placeholder="1111 2222 3333"
                                                    {...register("aadhar")}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <div className='w-full'>
                                            <label htmlFor="passport" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                Your Passport
                                            </label>
                                            <input
                                                type="text"
                                                name="Passport"
                                                id="empPassport"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="XXXPX1111X"
                                                {...register("empPassport")}

                                            />
                                        </div>
                                    )
                                    }

                                </div>
                                <div className="flex space-x-4">
                                    <div className='w-full'>
                                        <label htmlFor="phonenumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Your Phone Number
                                        </label>
                                        <input
                                            type="text"
                                            name="Phone Number"
                                            id="phoneNo"
                                            pattern="[0-9]{10}"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="91000 00000"
                                            {...register("ph", { required: true })}
                                            required
                                        />
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-secondary-600 dark:hover:bg-secondary-700 dark:focus:ring-blue-800"
                                >
                                    Submit Profile Information
                                </button>


                            </form>
                        </div>
                    </div>
                }
            </div>
        </section>
    );
};

export default UserLogin;