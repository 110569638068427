import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { showSuccessToast, showErrorToast } from "../../Utils/toastUtils";
import dateFormat from '../../Utils/dateFormatter'
import { useNavigate } from "react-router-dom";
import handleResponse from '../../Utils/responseHandler';
import { countries } from '../../data/dummy';


const Form = (props) => {
  const { register, handleSubmit, setValue } = useForm();
  const [selectedDomain, setSelectedDomain] = useState('');
  const [tnc, setTnc] = useState("");
  const [comments, setComments] = useState("");
  const [formData, setFormData] = useState({});
  const [selectedCountry, setSelectedCountry] = useState('');
  const [otherCountry, setOtherCountry] = useState('');
  const Navigate = useNavigate();
  useEffect(() => {

    axios.get(`/api/contract/get/one/${props.contractId}`, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('Token')
      }
    })
      .then((response) => response.data)
      .then((data) => {
        data.startDate = (data.startDate !== null) ? dateFormat(data.startDate) : ""
        data.endDate = (data.endDate !== null) ? dateFormat(data.endDate) : ""
        setSelectedCountry(data.country)
        setFormData(data);
        setTnc(data.tnc)
        setComments(data.comments)
        populateFormFields(data);
      })
      .catch((error) => {
        handleResponse(error, Navigate);
        console.log(error);
      }
      );
    axios.get(`/api/employee/emp`, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('Token')
      }
    })
      .then((response) => response.data)
      .then((data) => {
        setFormData(data);
        populateFormFields(data);
      })
      .catch((error) => {
        console.log(error);
      });

  }, [props.contractId]);

  const handleCountryChange = (event) => {
    event.preventDefault();
    setOtherCountry('')
    setSelectedCountry(event.target.value);
  };

  const populateFormFields = (data) => {
    Object.keys(data).forEach((fieldName) => {
      const field = domains.flatMap((domain) => domain.fields).find((f) => f.name === fieldName);
      if (field) {
        setValue(fieldName, data[fieldName]);
      }
    })
      ;
  };

  useEffect(() => {

    setValue('tnc', tnc);
  }, [tnc, setValue]);

  useEffect(() => {

    setValue('comments', comments);

  }, [comments, setValue]);


  const domains = [
    {
      name: 'General Info',
      fields: [
        { label: 'Company Name', type: 'text', name: 'companyName', Disabled: 'true' },
        { label: 'Street Address', type: 'text', name: 'streetAddress', Disabled: 'true' },
        { label: 'City/State/Zip', type: 'text', name: 'cityStateZip', Disabled: 'true' },
        { label: 'Country', type: 'text', name: 'country', Disabled: 'true' },
        { label: 'Pan Number', type: 'text', name: 'panNumber', Disabled: 'true', isDisabled: selectedCountry === 'India' ? false : true },
        { label: 'CIN Number', type: 'text', name: 'cinNumber', Disabled: 'true', isDisabled: selectedCountry === 'India' ? false : true },
        { label: 'Goods & Service Tax No.', type: 'text', name: 'gstNumber', Disabled: 'true', isDisabled: selectedCountry === 'India' ? false : true },
        { label: 'TAX / VAT NO.', type: 'text', name: 'taxID', Disabled: 'true', isDisabled: selectedCountry === 'India' ? true : false },
        { label: 'URL', type: 'text', name: 'url', Disabled: 'true' },
      ],
    },
    {
      name: 'Contact Info',
      fields: [
        { label: 'Sales Contact Name', type: 'text', name: 'salesContactName' },
        { label: 'Sales Contact Email', type: 'email', name: 'salesContactEmail' },
        { label: 'Sales Contact Phone', type: 'tel', name: 'salesContactPhone' },
        { label: 'Finance Contact Name', type: 'text', name: 'financeContactName', Disabled: 'true' },
        { label: 'Finance Contact Email', type: 'email', name: 'financeContactEmail', Disabled: 'true' },
        { label: 'Finance Contact Phone', type: 'tel', name: 'financeContactPhone', Disabled: 'true' },
      ],
    },
    {
      name: 'Campaign Info',
      fields: [
        { label: 'Description', type: 'text', name: 'description' },
        { label: 'Start Date', type: 'date', name: 'startDate' },
        { label: 'End Date', type: 'date', name: 'endDate' },
        { label: 'Daily Limit', type: 'text', name: 'dailyLimit' },
        { label: 'Ad Sizes', type: 'text', name: 'adSizes' },
        { label: 'Geo Targeting', type: 'text', name: 'geoTargeting' },
        { label: 'URL', type: 'text', name: 'campaignUrl' },
      ],
    },
    {
      name: 'Terms',
      fields: [
        { label: 'Deal Type', type: 'text', name: 'dealType' },
        { label: 'Rate', type: 'text', name: 'rate' },
        { label: 'Billing Source', type: 'text', name: 'billingSource' },
        { label: 'Payment Terms', type: 'text', name: 'paymentTerms' },
        { label: 'Cancellation Period', type: 'text', name: 'period' },
        { label: 'Total Budget', type: 'text', name: 'totalBudget' },
      ],
    },
  ];

  const toggleDomain = (domainName) => {
    if (selectedDomain === domainName) {
      setSelectedDomain(null);
    } else {
      setSelectedDomain(domainName);
    }
  };

  const [isProcessing, setIsProcessing] = useState(false)

  const onSubmit = (data) => {
    setIsProcessing(true);
    const domains = Object.keys(data);
    const isEmpty = domains.some((domain) => {
      const fields = data[domain];
      if (selectedCountry === "India" && (domain === "taxID" || domain === "comments") && fields.length === 0) {
        if (data.taxID.length !== 0) {
          data.taxID = ' ';
        }
        return false;
      }
      if (selectedCountry !== "India" && (domain === "panNumber" || domain === "cinNumber" || domain === "gstNumber" || domain === "comments") && fields.length === 0) {
        if (data.panNumber.length !== 0) {
          data.panNumber = ' ';
        }
        if (data.cinNumber.length !== 0) {
          data.cinNumber = ' ';
        }
        if (data.gstNumber.length !== 0) {
          data.gstNumber = ' ';
        }
        return false;
      }

      return fields.length === 0;
    });

    if (isEmpty) {
      showErrorToast("Error: Required fields not Filled!");
      setIsProcessing(false);
      return;
    }

    axios.put(`/api/contract/get/one/${props.contractId}`, data, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('Token')
      }
    })
      .then((response) => {
        showSuccessToast((response.data && response.data.Message) ? response.data.Message : "Success")
        Navigate(-1);
      })
      .catch((err) => {
        console.log(err)
        setIsProcessing(false);
        showErrorToast("Error!");
      }).finally(() => {
        setIsProcessing(false);

      })
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-3xl mx-auto p-6 bg-gray-100 mt-12 rounded shadow-md animate-slide-in-2"
    >
      {domains.map((domain, index) => (
        <div
          key={domain.name}
          className={`mb-4 p-6 border rounded transition-colors duration-300 ease-in-out ${selectedDomain === domain.name
            ? "border-blue-500"
            : "border-gray-300"
            }`}
        >
          <div
            className="flex justify-between items-center cursor-pointer transition-colors duration-300 ease-in-out"
            onClick={() => toggleDomain(domain.name)}
          >
            <h2 className="text-xl font-bold">{domain.name}</h2>
            {selectedDomain === domain.name ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-blue-500 transition-transform duration-300 ease-in-out transform rotate-180"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M15.293 7.293a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414l-2.5-2.5-2.5 2.5a1 1 0 1 1-1.414-1.414l3-3a1 1 0 0 1 1.414 0zM5 11a1 1 0 0 0-1"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-500 transition-transform duration-300 ease-in-out transform rotate-0"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 0 1 1 1v5h5a1 1 0 0 1 0 2h-5v5a1 1 0 0 1-2 0v-5H4a1 1 0 1 1 0-2h5V4a1 1 0 0 1 1-1z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
          {selectedDomain === domain.name && (
            <div className="mt-4 transition-all duration-300 ease-in-out">
              {domain.fields.map((field) => (
                <div
                  style={{
                    display: field.isDisabled ? "none" : "block"
                  }
                  }
                  className="mb-4" key={field.name}>
                  {field.name === 'country' && (
                    <div>
                      <label htmlFor={field.name} className="block font-medium mb-2">
                        {field.label}:
                      </label>
                      <select
                        className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                        id={field.name}
                        {...register(field.name)}
                        value={selectedCountry} onChange={handleCountryChange}
                        disabled={field.Disabled}
                        required>
                        {countries.map((country) => (
                          <option key={country.name} value={country.name}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {(field.name !== 'country' && (field.name === 'panNumber' || field.name === 'cinNumber' || field.name === 'gstNumber')) && (
                    <div>
                      <label htmlFor={field.name} className="block font-medium mb-2">
                        {field.label}:
                      </label>
                      <input
                        type={field.type}
                        disabled={field.Disabled}
                        id={field.name}
                        {...register(field.name)}
                        className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                        required={selectedCountry === 'India'}
                      />
                    </div>
                  )}
                  {(field.name !== 'country' && field.name === 'taxID') && (
                    <div>
                      <label htmlFor={field.name} className="block font-medium mb-2">
                        {field.label}:
                      </label>
                      <input
                        type={field.type}
                        disabled={field.Disabled}
                        id={field.name}
                        {...register(field.name)}
                        className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                        required={selectedCountry !== 'India'}
                      />
                    </div>
                  )}
                  {(field.name !== 'country' && field.name !== 'taxID' && field.name !== 'panNumber' && field.name !== 'cinNumber' && field.name !== 'gstNumber') && (
                    <div>
                      <label htmlFor={field.name} className="block font-medium mb-2">
                        {field.label}:
                      </label>
                      <input
                        type={field.type}
                        disabled={field.Disabled}
                        id={field.name}
                        {...register(field.name)}
                        className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
                        required
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      <div className="mb-4 p-6 border rounded transition-colors duration-300 ease-in-out border-blue-500">
        <h2 className="text-xl font-bold mb-4">Terms and Conditions</h2>

        <div className="mt-2">
          <textarea
            id="tnc"
            {...register('tnc')}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            placeholder="Enter T&C"
            value={tnc}
            onChange={(e) => setTnc(e.target.value)}
            rows={tnc ? Math.ceil(tnc.length / 100) : 1}
          ></textarea>
        </div>
      </div>

      <div className="mb-4 p-6 border rounded transition-colors duration-300 ease-in-out border-blue-500">

        <label>
          Any Comments
        </label>
        <div className="mt-2">
          <textarea
            id="comments"
            {...register("comments")}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            placeholder="Enter comments"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          ></textarea>
        </div>

      </div>

      <button
        type="submit"
        disabled={isProcessing}
        className={`${isProcessing ? "bg-gray-500 hover:bg-gray-700" : "bg-blue-500 hover:bg-blue-700"} flex mx-auto  text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out`}

      >
        Submit
      </button>
    </form>
  );

};

export default Form;