import React, { useState, useRef } from 'react';
import axios from 'axios';
import { showSuccessToast, showErrorToast } from "../../Utils/toastUtils";
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false)
  const captchaRef = useRef(null)

  const handleSubmit = async (e) => {
    setProcessing(true)
    e.preventDefault();
    try {

      const captchaToken = captchaRef.current.getValue();
        captchaRef.current.reset();
        if(captchaToken === ''){
            showErrorToast('Complete Captcha');
            return;
        }
        // data.captchaToken = token

      const response = await axios.post('/api/reset/request', { email , captchaToken });
      showSuccessToast(response.data.message);
      navigate('/');

    } catch (error) {
      showErrorToast(error.response.data.message);
    } finally {
      setProcessing(false);
    }
  };
  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 animate-slide-in-2">
        <a className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          Adsolut CRM
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 animate-slide-in-1 ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Reset Your Password
            </h1>
            <form className="space-y-4 md:space-y-6" >
              <label htmlFor="email" className="block text-sm font-medium text-gray-900 dark:text-white">Your email</label>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required />

              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
              />

              <button type="submit"
                disabled={processing}
                onClick={(e) => handleSubmit(e)}
                className={`w-full text-white ${!processing ? "bg-blue-600 hover:bg-blue-700" : "bg-gray-600 hover:bg-gray-700"} focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-secondary-600 dark:hover:bg-secondary-700 dark:focus:ring-blue-800`}>{!processing ? "Submit" : "Processing..."}</button>
            </form>

          </div>
        </div>
      </div>
    </section >
  );
};

export default ForgotPassword;
