import React from 'react'
import { useLocation } from 'react-router-dom';
import ContractForm from "./Forms/contractshowForm";
import ContractTableView from './Forms/ContractTableView'

const Contract = () => {
  const location = useLocation();
  const contractId = location.pathname.split('/')[3]
  const editMode = location.pathname.endsWith('edit')



  const userType = sessionStorage.getItem('role') === 'Employee' || 'Admin';

  return (
    editMode ? (
      < ContractForm contractId={contractId} />
    ) :
      < div >
        {contractId && !userType && (
          <div>
            <ContractForm contractId={contractId} />
          </div>
        )}
        {
          contractId && userType && (
            <div>
              <ContractTableView contractId={contractId} />
            </div>
          )
        }
      </ div>)
}

export default Contract