import React from 'react';
import { Link } from 'react-router-dom';
import errorlogo from '../src/assets/internalserver.webp';
const InternalServerError = () => {
  return (
    <section className="bg-white font-serif">
      <div className='flex justify-center flex-col'>
        <div className='flex justify-center  '>

          <img className="object-cover h-96 w-100" src={errorlogo} alt='img-500-error'/>
        </div>
        <h1 className="text-center text-8xl ">
          500
        </h1>
        <div className='text-center'>

          <h3 className="text-3xl m-3">Something Went Wrong!</h3>
          <p className="mt-10">The page you are looking for is not available!</p>
          <Link to="/dashboard" className="link_404 inline-block mt-20 px-10 py-2 bg-green-500 text-white">
            Go to Home
          </Link>
        </div>
      </div>



    </section>
  );
};

export default InternalServerError;
