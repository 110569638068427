import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { showErrorToast } from "../Utils/toastUtils";
import { useStateContext } from "../contexts/ContextProvider";

const OverallStats = () => {
    const [rows, setRows] = useState([])
    const [totalCount, setTotalCount] = useState([])

    const { currentColor } = useStateContext()
    const [loading, setLoading] = useState(false)
    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await axios.get('/api/dashboard/dash', {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.getItem('Token')
                }
            })
            const arr = result.data.company
            setRows(arr)
            const ans = arr.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);
            setTotalCount(ans)

        } catch (error) {
            showErrorToast("Error!");

            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])
    
    return (
        rows && <div className="lg:w-6/12 h-100 px-4 mt-9  animate-slide-in-2">
            <div className=" step-two relative bg-green-50 flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-4 py-3 border-0 w-100">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 className="font-semibold text-base text-blueGray-700">
                                Overall Stats
                            </h3>
                        </div>
                    </div>
                </div>

                <div className="block  w-full xs:w-1/2 ">
                    <table className="items-center w-full border-collapse text-blueGray-700  ">
                        <thead className="thead-light ">
                            <tr>
                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                    Company
                                </th>
                                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0  font-semibold text-left">
                                    Publishers
                                </th>
                                <th className="px-6 bg-blueGray-50 text-blueGray-700 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px">
                                    Progress
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                rows.length > 0 && rows.map((item) => (
                                    <tr key={item._id}>
                                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs  p-4 text-left">
                                            {item.companyName}
                                        </th>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item.count}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs  p-4">
                                            <div className="flex items-center">
                                                <span className="mr-2">
                                                    {Math.round((item.count * 100) / totalCount)}%
                                                </span>
                                                <div className="relative w-full">
                                                    <div className="overflow-hidden h-2 text-xs opac flex rounded bg-gray-200">
                                                        <div
                                                            style={{
                                                                width: `${Math.round((item.count * 100) / totalCount)}%`,
                                                                backgroundColor: currentColor
                                                            }}
                                                            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div >


    )
}

export default OverallStats


