import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import { BsFillEyeFill, BsPencilFill, BsCheck } from "react-icons/bs";
import { useStateContext } from "../../contexts/ContextProvider";
import { showSuccessToast, showErrorToast } from '../../Utils/toastUtils';


const Profile = () => {
    const Navigate = useNavigate();
    const [userProfile, setUserProfile] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isEditing1, setIsEditing1] = useState(false);
    const [isEditing2, setIsEditing2] = useState(false);
    const [isEditing3, setIsEditing3] = useState(false);
    const [employeeId, setEmployeeId] = useState(null);
    const navigate = useNavigate();
    const { currentColor } = useStateContext();


    const [newPanCard, setNewPanCard] = useState("");
    const [country, setCountry] = useState("India");
    const [newPassport, setNewPassport] = useState("");
    const [newPhone, setNewPhone] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [newAadharCard, setNewAadharCard] = useState("");
    const userType = sessionStorage.getItem('role');

    const handleButtonClick = () => {
        navigate(`/employee/docs/${employeeId}`);
    };

    const getData = async () => {
        await axios.get(`/api/employee/details/all`, {
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('Token'),
                'role': sessionStorage.getItem('role'),
            }
        })
            .then((response) => {

                return response
            })
            .then((response) => {
                const data = response.data
                setEmployeeId(data._id);
                setUserProfile(data);
                setNewPanCard(data.pan)
                setNewEmail(data.emailId)
                setNewAadharCard(data.aadhar)
                setNewPhone(data.ph)
                setNewPassport(data.empPassport)
                setCountry(data.empCountry);
            })
            .catch((error) => {

                if (error.response.status === 401) {
                    sessionStorage.clear()
                    Navigate('/');
                }
                console.log(error);
            });
    }
    const putData = async (data) => {
        const res = await axios.put('/api/employee/details', data, {
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('Token')
            }
        })
            .then((response) => response.data)
            .then((data) => {
                setEmployeeId(data._id);
                setUserProfile(data);
                setNewPassport(data.empPassport)
                setNewPanCard(data.pan)
                setNewEmail(data.emailId)
                setNewAadharCard(data.aadhar)
                setNewPhone(data.ph)
            })
            .then(() => {
                showSuccessToast("Success!")
            })
            .catch((error) => {
                showErrorToast("Failed!")
                console.log(error);
            });
    }

    const handleSubmit = (e, field) => {
        e.preventDefault();
        const temp = userProfile

        if (field === 'pan') {
            setNewPanCard(newPanCard)
            const obj = { "pan": newPanCard }
            putData(obj)
            setIsEditing1(!setIsEditing1);

        } else if (field === 'aadhar') {
            setNewAadharCard(newAadharCard)
            const obj = { "aadhar": newAadharCard }
            putData(obj)
            setIsEditing2(!setIsEditing2);

        } else if (field === 'ph') {
            setNewPhone(newPhone)
            const obj = { "ph": newPhone }
            putData(obj)
            setIsEditing3(!setIsEditing3);

        }
        else {
            setIsEditing(!setIsEditing);
            setNewPassport(newPassport)
            const obj = { "empPassport": newPassport }
            putData(obj)
        }
    };

    useEffect(() => {
        getData()
    }, []);

    const handleViewClick = () => {
        window.open('https://example.com/view-pan-card');
    };

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };
    const handleEditClick1 = () => {
        setIsEditing1(!isEditing1);
    };

    const handleEditClick2 = () => {
        setIsEditing2(!isEditing2);
    };

    const handleEditClick3 = () => {
        setIsEditing3(!isEditing3);
    };
    return (
        <div className='mt-20 flex flex-col justify-center items-center h-full w-full xs:w-screen'>

            <div className="bg-white shadow rounded-lg border h-full lg:w-1/2 md:w-3/4  sm:w-full">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        User Profile
                    </h3>
                    <p className="mt-1 max-w-2xl text-md text-gray-500">
                        Hey! This is your information
                        <br />
                    </p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Full name</dt>
                            <dd className="flex justify-between mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {userProfile && userProfile.firstName && userProfile.lastName ? userProfile.firstName + " " + userProfile.lastName : ""}
                            </dd>
                        </div>

                        <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Email Address</dt>
                            <dd className="flex justify-between items-center mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <div className="flex items-center">
                                        <>
                                            {userProfile && userProfile.emailId ? userProfile.emailId : ""}
                                        </>
                                </div>

                            </dd>
                        </div>

                        {userType !== 'Publisher' &&
                        <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Country</dt>
                            <dd className="flex justify-between items-center mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <div className="flex items-center">
                                        <>
                                            {userProfile && userProfile.empCountry ? userProfile.empCountry : ""}
                                        </>
                                </div>
                            </dd>
                        </div>
                        }
                        {userType !== 'Publisher' &&
                            <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Phone No.</dt>
                                <dd className="flex justify-between items-center mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <div className="flex items-center">
                                        {isEditing3 ? (
                                            <>
                                                <input
                                                    type="text"
                                                    value={newPhone}
                                                    onChange={(e) => setNewPhone(e.target.value)}
                                                    className="border border-gray-300 px-2 py-1 rounded"
                                                />
                                                <button
                                                    onClick={(e) => { handleSubmit(e, 'ph') }}

                                                    disabled={!newPhone}
                                                    className="ml-2 bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded"
                                                >
                                                    <BsCheck />
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                {userProfile && userProfile.ph ? userProfile.ph : ""}

                                            </>
                                        )}
                                    </div>
                                    <button
                                        onClick={handleEditClick3}
                                        className="ml-2 text-blue-500 hover:text-blue-600"
                                    >
                                        <BsPencilFill style={{ color: currentColor }} />
                                    </button>
                                </dd>
                            </div>
                        }

                        {country && country === 'India' ? (
                            <>
                                {userType !== 'Publisher' &&
                                    <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Pan Card</dt>
                                        <dd className="flex justify-between items-center mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            <div className="flex items-center">
                                                {isEditing1 ? (
                                                    <>
                                                        <input
                                                            type="text"
                                                            value={newPanCard}
                                                            onChange={(e) => setNewPanCard(e.target.value)}
                                                            className="border border-gray-300 px-2 py-1 rounded"
                                                        />
                                                        <button
                                                            onClick={(e) => { handleSubmit(e, 'pan') }}

                                                            disabled={!newPanCard}
                                                            className="ml-2 bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded"
                                                        >
                                                            <BsCheck />
                                                        </button>
                                                    </>
                                                ) : (
                                                    <>
                                                        {userProfile && userProfile.panDoc ? (
                                                            <>
                                                                {userProfile && userProfile.pan ? userProfile.pan : "BIO12312312"}
                                                                <button
                                                                    onClick={handleViewClick}
                                                                    className="ml-2 bg-gray-500 hover:bg-gray-600 text-white py-1 px-2 rounded"
                                                                >
                                                                    <BsFillEyeFill />
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <div className='flex flex-col xs:flex-row justify-between items-start'>
                                                                <p className=' mb-2'>
                                                                    {userProfile && userProfile.pan ? userProfile.pan : ""}

                                                                </p>

                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <button
                                                onClick={handleEditClick1}
                                                className="ml-2 text-blue-500 hover:text-blue-600"
                                            >
                                                <BsPencilFill style={{ color: currentColor }} />
                                            </button>
                                        </dd>
                                    </div>
                                }

                                {userType !== 'Publisher' &&
                                    <div className=" py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Aadhar Card</dt>
                                        <dd className="flex justify-between items-center mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            <div className="flex items-center">
                                                {isEditing2 ? (
                                                    <>
                                                        <input
                                                            type="text"
                                                            value={newAadharCard}
                                                            onChange={(e) => setNewAadharCard(e.target.value)}
                                                            className="border border-gray-300 px-2 py-1 rounded"
                                                        />
                                                        <button
                                                            onClick={(e) => { handleSubmit(e, 'aadhar') }}

                                                            disabled={!newAadharCard}
                                                            className="ml-2 bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded"
                                                        >
                                                            <BsCheck />
                                                        </button>
                                                    </>
                                                ) : (
                                                    <>
                                                        {userProfile && userProfile.aadharDoc ? (
                                                            <>
                                                                {userProfile && userProfile.aadhar ? userProfile.aadhar : ""}


                                                                <button
                                                                    onClick={handleViewClick}
                                                                    className="ml-2 bg-gray-500 hover:bg-gray-600 text-white py-1 px-2 rounded"
                                                                >
                                                                    <BsFillEyeFill />
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <div className='flex flex-col justify-between items-start'>
                                                                <p className=' mb-2'>
                                                                    {userProfile && userProfile.aadhar ? userProfile.aadhar : ""}

                                                                </p>

                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <button
                                                onClick={handleEditClick2}
                                                className="ml-2 text-blue-500 hover:text-blue-600"
                                            >
                                                <BsPencilFill style={{ color: currentColor }} />
                                            </button>
                                        </dd>
                                    </div>
                                }
                            </>
                        ) : (
                            <>
                                {userType !== 'Publisher' &&
                                    <div className=" py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Passport</dt>
                                        <dd className="flex justify-between items-center mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            <div className="flex items-center">
                                                {isEditing ? (
                                                    <>
                                                        <input
                                                            type="text"
                                                            value={newPassport}
                                                            onChange={(e) => setNewPassport(e.target.value)}
                                                            className="border border-gray-300 px-2 py-1 rounded"
                                                        />
                                                        <button
                                                            onClick={(e) => { handleSubmit(e, 'empPassport') }}

                                                            disabled={!newPassport}
                                                            className="ml-2 bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded"
                                                        >
                                                            <BsCheck />
                                                        </button>
                                                    </>
                                                ) : (
                                                    <>
                                                        {userProfile && userProfile.passportDoc ? (
                                                            <>
                                                                {userProfile && userProfile.empPassport ? userProfile.empPassport : ""}


                                                                <button
                                                                    onClick={handleViewClick}
                                                                    className="ml-2 bg-gray-500 hover:bg-gray-600 text-white py-1 px-2 rounded"
                                                                >
                                                                    <BsFillEyeFill />
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <div className='flex flex-col justify-between items-start'>
                                                                <p className=' mb-2'>
                                                                    {userProfile && userProfile.empPassport ? userProfile.empPassport : ""}

                                                                </p>

                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <button
                                                onClick={handleEditClick}
                                                className="ml-2 text-blue-500 hover:text-blue-600"
                                            >
                                                <BsPencilFill style={{ color: currentColor }} />
                                            </button>
                                        </dd>
                                    </div>
                                }
                            </>
                        )
                        }
                        {userType !== 'Publisher' &&
                            <div className='flex justify-center items-center'>

                                <button
                                    style={{ background: currentColor }}
                                    onClick={handleButtonClick}
                                    className=" mt-4 mb-4 text-white font-bold py-2 px-4 rounded"
                                >
                                    Documents
                                </button>
                            </div>
                        }
                    </dl>
                </div>
            </div>
        </div>
    );
};

export default Profile;

