import React, { useEffect, useState } from 'react';
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page } from '@syncfusion/ej2-react-grids';
import axios from "axios";
import { Header } from '../components';
import { useNavigate } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import GenerateNewPDF from '../modals/GenerateNewPDF';
import GenerateNewPDF1 from '../modals/GenerateNewPDF1';
import { useStateContext } from "../contexts/ContextProvider";

const PdfApproval = () => {

    const { currentColor } = useStateContext();
    // const toolbarOptions = ['Search'];
    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);

    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const userType = sessionStorage.getItem('role');
    const [loading, setLoading] = React.useState(false);
    const [contractId, setContractId] = React.useState('')

    useEffect(() => {
        getData();
    }, []);


    const getData = () => {
        setLoading(true);
        axios.get('/api/pdf/getpending', {
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem('Token')
            }
        })
            .then(response => {
                setRows(response.data.reverse());
            })
            .catch(error => {
                console.error(error);
            }).finally(() => {
                setLoading(false);
            })
    };





    const actionsTemplate = (args) => {
        return (

            <div className='flex flex-row '>

                <button
                    style={{ background: currentColor }}
                    className="flex  bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out"
                    onClick={() => { setContractId(args._id); setModal1(true) }}

                >
                    Generate Agreement
                </button>
            </div >
        );
    };
    const handleRowClick = (args) => {

        navigate(`/publisher/contract/${args.data._id}`);
    };


    if (userType === 'Admin') {
        return (
            <>
                <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
                    <Header category="Page" title="Approval For Agreements" />
                    {
                        loading ? (
                            <div className="flex justify-center m-3">
                                <HashLoader color={currentColor} />
                            </div>
                        ) : (

                            <GridComponent
                                width='100%'
                                id="userGrid"
                                dataSource={rows}
                                allowPaging
                                allowSorting
                                pageSettings={{ pageCount: 5 }}
                                // toolbar={toolbarOptions}
                                rowSelected={handleRowClick}

                            >
                                <ColumnsDirective>
                                    <ColumnDirective field="_id" headerText="Contract ID" width="180" />
                                    <ColumnDirective field="publisherId.name" headerText="Publisher Name" width="120" />
                                    <ColumnDirective headerText="Employee Name" template={(rowData) => (
                                        <span>{rowData.publisherId.employeeId.firstName} {rowData.publisherId.employeeId.lastName}</span>
                                    )} width="120" />
                                    <ColumnDirective field="approvalStatus" headerText="Approval Status" width="120" />


                                    {userType && userType === 'Admin' ? <ColumnDirective headerText="Actions" template={actionsTemplate} width="190" /> : <></>}
                                </ColumnsDirective>
                                <Inject services={[Search, Page]} />
                            </GridComponent>
                        )}
                    <div
                        style={{
                            float: "right",
                            marginRight: "20px",
                            paddingTop: "15px",
                        }}
                    >
                        <div
                            className=" flex justify-end px-3"
                            style={{
                                position: "fixed",
                                bottom: 0,
                                left: 0,
                                right: 0,
                                zIndex: 9999,
                                padding: "10px"
                            }}
                        >
                            
                        </div>
                    </div>

                    <GenerateNewPDF1
                        getData={getData}
                        modal={modal1}
                        setModal={setModal1}
                        contractId={contractId}
                    />

                </div>
            </>
        );
    } else {
        return (
            <></>
        )
    }
};

export default PdfApproval;
